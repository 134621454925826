import { PureComponent } from 'react';
import T from 'prop-types';
import {
  selectionsShape
} from 'reducers/selections';

import {
  currentUserShape
} from 'reducers/current_user';

class SelectionsSubmit extends PureComponent {
  static propTypes = {
    selections: selectionsShape.isRequired,
    currentUser: currentUserShape.isRequired,
    submitSelections: T.func.isRequired
  }

  componentDidMount() {
    const {
      selections,
      currentUser
    } = this.props;

    if (selections.id === undefined && currentUser.data.firstName !== undefined) {
      this.submit();
    }
  }

  componentWillUnmount() {
    this.submit();
  }

  submit() {
    const {
      submitSelections,
      selections,
      currentUser
    } = this.props;

    submitSelections(selections, currentUser);
  }

  render() {
    return null;
  }
}

export default SelectionsSubmit;
