import createReducer from 'config/create_reducer';
import Constants from 'config/constants';
import update from 'immutability-helper';

const normalizeLocations = (locations) => locations
  .map(({ countryId, imageCategoryId, ...other }) => ({
    ...other
    // id: parseInt(countryId, 10),
    // imageCategoryId: parseInt(imageCategoryId, 10)
  }));

const getDefaultState = () => ({
  fetching: false,
  collection: []
});

const locations = createReducer(getDefaultState(), {
  [Constants.Locations.FETCH](state) {
    return {
      ...state,
      fetching: true
    };
  },

  [Constants.Locations.FETCH_FAILURE](state) {
    return {
      ...state,
      fetching: false
    };
  },

  [Constants.Locations.FETCH_SUCCESS](state, action) {
    const selected = state
      .collection
      .filter((item) => item.selected === true);
    const selectedIds = selected.map((item) => item.id);

    const newCollection = selected
      .concat(normalizeLocations(action.data).filter((item) => !selectedIds.includes(item.id)));

    return {
      ...state,
      fetching: false,
      collection: newCollection,
      fetchingQuery: action.query
    };
  },

  [Constants.Locations.FETCH_IMAGE_SUCCESS](state, action) {
    const {
      data: { id, imageUrl }
    } = action;

    if (!imageUrl) {
      return state;
    }

    const index = state.collection.findIndex((item) => item.imageCategoryId === id);
    if (index === -1) {
      return state;
    }

    const collection = update(state.collection, {
      [index]: {
        imageUrl: {
          $set: imageUrl
        }
      }
    });

    return {
      ...state,
      collection
    };
  },

  [Constants.Locations.RESET](state) {
    return {
      ...state,
      collection: state.collection.filter((item) => item.selected === true)
    };
  }
});

export default locations;
export * from './prop_types';
