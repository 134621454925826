import React, { useState } from 'react';
import T from 'prop-types';
import {
  Link
} from 'react-router-dom';
import {
  withRouter,
  matchPath
} from 'react-router';
import cs from 'classnames';

import styles from './styles.module.scss';

const renderLink = (step) => (
  <Link
    to={step.path}
    className={styles.link}
    key={step.title}
  >
    <span className={styles.name}>{step.title}</span>
  </Link>
);


const ProgressBar = ({ location: { pathname = '/' }, steps, show }) => {
  const activeRouteIndex = steps.findIndex(
    (step) => matchPath(pathname, { path: step.path, exact: step.exact }) !== null
  );
  const distinctSteps = steps;

  const currentPath = location.pathname;
  const currentStepIndex = distinctSteps.findIndex(item => item.path.includes(currentPath));

  const style = { width: `calc(100%/${distinctSteps.length})` };


  return (
    <>
      {show &&
        <div className={styles.root}>{
          distinctSteps.map((step, index) => (
            <div
              style={style}
              className={
                cs(styles.step, {
                  [styles.active]: index === currentStepIndex,
                  [styles.completed]: index < currentStepIndex
                })
              }
              key={step.title}
            >
              {renderLink(step)}
            </div>
          ))
        }
        </div>
      }
    </>
  );
};
ProgressBar.propTypes = {
  location: T.shape({
    pathname: T.string.isRequired
  }).isRequired,
  steps: T.arrayOf(T.shape({
    path: T.string.isRequired,
    exact: T.bool,
    title: T.string.isRequired
  })).isRequired
};

export default withRouter(ProgressBar);
