import React, { FunctionComponent, useEffect, useRef } from 'react'

const Logo = ({ className }) => {
  const ref = useRef(null)

  return (
      <a
        className={className}
        ref={ref}
        role='button'
        title='Travelstride Logo'
      >
        <div />
      </a>
  )
}
export default Logo
