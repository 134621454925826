import {
  connect
} from 'react-redux';
import Constants from 'config/constants';
import LodgingType from './lodging_type';

const mapStateToProps = ({ selections: { lodging, locations, dates, travelers, budget } }) => ({
  collection: lodging,
  locations,
  dates,
  travelers,
  data: budget
});


const mapDispatchToProps = (dispatch) => ({
  select: (data) => {
    dispatch({
      type: Constants.Selections.CHANGE,
      subject: 'lodging',
      data
    });
  },
  updateAmount: (data) => {
    dispatch({
      type: Constants.Selections.CHANGE,
      subject: 'budget',
      data
    });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(LodgingType);
