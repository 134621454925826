import React, { useCallback } from 'react';
import T from 'prop-types';
import { travelersShape } from 'reducers/selections';
import update from 'immutability-helper';

import Checkbox from 'stride-core-ui/checkbox';
import styles from './styles';
import Chip from 'components/chip';


const TravelersAge = ({ data, select }) => {
  const onChange = useCallback((name) => {
    const value = !data.ages.find((age) => age.name === name).value;
    const index = data.ages.findIndex((age) => age.name === name);
    const nextData = update(data, {
      ages: {
        [index]: {
          value: {
            $set: value
          }
        }
      }
    });

    select(nextData);
  }, [data]);

  return (
    <>
      <span className={styles.subTitle}>
        What age groups do the travelers fit into? <h1>(Select all that apply)</h1>
      </span>
      <div class={styles.chipContainer}>
        {data.ages.map((age) => (
          <Chip
            handleClick={onChange}
            isActive={age.value}
            label={age.name}
            checked={age.value}
            id={age.name}
          />
        ))}
      </div>
    </>
  );
};
TravelersAge.propTypes = {
  data: travelersShape.isRequired,
  select: T.func.isRequired
};

export default TravelersAge;
