import React, { useCallback } from 'react';
import T from 'prop-types';

import styles from './styles';


const RadioInput = ({
  onChange,
  name,
  checked,
  label
}) => {
  const handleChange = useCallback((e) => { onChange(name, e.target.checked); }, [onChange]);

  return (
    <div className={styles.root}>
      <input
        id={name}
        type="radio"
        checked={checked}
        onChange={handleChange}
        className={styles.input}
      />
      <label htmlFor={name} onClick={() => document.getElementById(name).click()}
      >{label}</label>
    </div>
  );
};
RadioInput.propTypes = {
  onChange: T.func.isRequired,
  name: T.string.isRequired,
  label: T.string.isRequired,
  checked: T.bool.isRequired
};

export default RadioInput;
