import React, { useEffect } from 'react';
import T from 'prop-types';
import { datesShape } from 'reducers/selections';
import Dialog from 'components/dialog';

import BlockNavigation from 'stride-core-ui/block_navigation';
import ErrorMessage from 'components/error_message';
import Controls from 'components/controls';
// import SelectionsSubmit from 'components/selections_submit';
import ExactDatesSelect from './exact_dates_select';
import ApproxDatesSelect from './approx_dates_select';
import DurationSelect from './duration_select';
import styles from './styles.module.scss';
import Header from 'components/header';
import ReactGA from 'react-ga4';

const BLOCKED_ROUTES = [{
  pathname: [
    '/what-kind-of-trip-are-you-looking-for',
    '/signin',
    '/home-country',
    '/budget',
    '/completed'
  ],
  exact: false
}];

const validate = (data) => {
  if (data.startDate && data.endDate) {
    return true;
  }

  if (data.kind === 'approx' && data.departureMonth && data.duration && data.departureYear) {
    return true;
  }

  if (data.kind === 'duration' && data.duration) {
    return true;
  }

  return false;
};

const getTitle = (data) => {
  if (data.kind === 'exact' && data.startDate && data.endDate) {
    return <>I will be traveling from <span>{data.startDate.format("DD MMM YYYY")}</span> to <span>{data.endDate.format("DD MMM YYYY")}</span></>;
  }
  if (data.kind === 'approx' && data.departureMonth && data.departureYear && data.duration) {
    return <>I will be traveling for <span>{data.duration.value} days</span> on <span>{data.departureMonth.label} {data.departureYear.value}</span></>;
  }
  if (data.kind === 'duration' && data.duration) {

    return <>I will be traveling for <span>{data.duration.value} days</span></>;
  }
  return '';
};
ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search, title: 'Travel Dates' });

const TravelDates = ({ data, select }) => (
  <>
    <Header isLastStep={false} isFirstStep={false} />
    <Dialog
      title="When will you be traveling?"
    >
      <span className={styles.title}>
        {getTitle(data)}
      </span>
      <div className={styles.datesContainer}>
        <ExactDatesSelect data={data} select={select} />
        <ApproxDatesSelect data={data} select={select} />
        <DurationSelect data={data} select={select} />
      </div>

      <BlockNavigation
        to={BLOCKED_ROUTES}
        disabled={validate(data)}
        autoRelease={false}
      >
        <ErrorMessage>Please select one of the options above</ErrorMessage>
      </BlockNavigation>

      <Controls
        prev={`/who-is-traveling${location.search}`}
        next={`/what-type-of-lodging-do-you-want${location.search}`}
        nextDisabled={!validate(data)}
        buttonLabel={'Next'}
      />
    </Dialog>
  </>
);
TravelDates.propTypes = {
  data: datesShape.isRequired,
  select: T.func.isRequired
};
export default TravelDates;
