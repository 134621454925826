import {
  connect
} from 'react-redux';
import Legend from './legend';

const mapStateToProps = ({ selections }) => ({
  data: selections
});


export default connect(mapStateToProps)(Legend);
