import React, { useEffect, useState } from "react";
import cs from "classnames";
import styles from "./styles.module.scss";
import { ReactComponent as ThreeStars } from "assets/images/3stars.svg";
import { ReactComponent as FourStars } from "assets/images/4stars.svg";
import { ReactComponent as FiveStars } from "assets/images/5stars.svg";
import { ReactComponent as PrivateGuided } from "assets/images/privateGuided.svg";
import { ReactComponent as SharedTour } from "assets/images/sharedTour.svg";
import { ReactComponent as MixedTour } from "assets/images/mixedTour.svg";
import { ReactComponent as MoneySvg } from "assets/images/money.svg";
import CloseIcon from "assets/images/threeStars.png";
const Chip = ({
  name,
  isActive,
  data,
  description,
  price,
  image,
  onSelect = () => {},
  setSliderValue = () => {},
}) => {
  const Onclick = (data) => {
    onSelect(data);
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  return (
    <div
      className={cs(styles.card, {
        [styles.active]: isActive,
      })}
      onClick={() => {
        Onclick(data);
      }}>
      <div className={styles.svgContainer}>
        <img src={image} alt="" />
      </div>
      <div className="flex flex-col w-2/3 shrink gap-2">
        <div className={styles.label}>{name}</div>
        {description && description.length > 0 && (
          <div className={styles.description}>{description}</div>
        )}
        {/* {price >= 0 && (
          <div className={styles.price}>
            Avg. {formatCurrency(price)} <br />
            <span>person per day</span>
          </div>
        )} */}
        {name === "Independent" && (
          <div className="flex items-center">
            <MoneySvg />
          </div>
        )}
        {name === "Group Tours" && (
          <div className="flex items-center">
            <MoneySvg />
            <MoneySvg />
          </div>
        )}
        {name === "Mixed" && (
          <div className="flex items-center">
            <MoneySvg />
            <MoneySvg />
            <MoneySvg />
          </div>
        )}
        {name === "Private Guided" && (
          <div className="flex items-center">
            <MoneySvg />
            <MoneySvg />
            <MoneySvg />
            <MoneySvg />
          </div>
        )}
      </div>
    </div>
  );
};

export default Chip;
