import { connect } from 'react-redux';
import Constants from 'config/constants';
import ServerAuthentication from './server_authentication';


const mapStateToProps = ({ currentUser: { authenticated, fetched } }) => ({
  authenticated,
  fetched
});

const mapDispatchToProps = (dispatch) => ({
  fetchCurrentUser: () => {
    dispatch({
      type: Constants.CurrentUser.FETCH
    });
  }
});


export default connect(mapStateToProps, mapDispatchToProps)(ServerAuthentication);
