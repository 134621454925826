import React from 'react';
import T from 'prop-types';
import cs from 'classnames';
import Plugin from 'stride-core-ui/plugin';
import styles from './styles';

const Input = ({
  pluginLeft,
  pluginRight,
  className: customClassName,
  isInvalid,
  error,
  ...other
}) => {
  const className = cs(
    styles.input,
    customClassName,
    { [styles.paddedLeft]: Boolean(pluginLeft) }
  );

  return (
    <div>
      <div className={ styles.body }>
        {
          pluginLeft ? (
            <Plugin position="left">
              { pluginLeft }
            </Plugin>
          ) : null
        }

        <input
          className={ className }
          { ...other }
        />

        {
          pluginRight ? (
            <Plugin position="right">
              { pluginRight }
            </Plugin>
          ) : null
        }
      </div>
      {
        isInvalid
          ? <div className={ styles.error }>{ error }</div>
          : null
      }
    </div>
  );
};
Input.propTypes = {
  pluginLeft: T.node,
  pluginRight: T.node,
  className: T.string,
  isInvalid: T.bool,
  error: T.string
};
Input.defaultProps = {
  pluginLeft: undefined,
  pluginRight: undefined,
  className: undefined,
  isInvalid: false,
  error: undefined
};

export default Input;
