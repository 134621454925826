/*eslint-disable*/
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import Dialog from "../../components/dialog";
import ContactDetailsForm from "./form";
import Controls from "../../components/controls";
import SelectionsSubmit from "../../components/selections_submit";
import styles from "./styles";
import Api from "../../config/api";
import selections from "sagas/selections";
import Header from 'components/header';
import ReactGa from 'react-ga4';

const ContactDetails = ({ selections, currentUser, updateId }) => {
  const formRef = useRef();
  const history = useHistory();
  const [pending, setPending] = useState(false);
  const [countries, setCountries] = useState([]);
  const id = localStorage.getItem("customTripResponse");
  // const id = "2e873f2b-2090-47fb-888d-0fbd373f6d9e";
  const primary_country = selections.locations && selections.locations[0] ? selections.locations[0].countryName : '';
  const urlParams = new URLSearchParams(location.search || '');
  urlParams.set('itinerary', id);
  urlParams.set('primary_country', primary_country);

  // useEffect(() => {
  //   urlParams.set('itinerary', id);
  //   urlParams.set('primary_country', primary_country);
  // }, [id, primary_country]);


  const onNextClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setPending(true);
    let id = localStorage.getItem("customTripResponse");
    urlParams.set('itinerary', id);
    urlParams.set('primary_country', primary_country);

    if (formRef.current && formRef.current.handleSubmit) {
      const context = formRef.current.getFormikContext();
      context.submitForm().then(() => {
        const validationErrors = context.validate(context.values || {});
        if (!Object.keys(validationErrors).length) {
          const params = {
            first_name: context.values.firstName,
            last_name: context.values.lastName,
            email: context.values.email,
            phone_number: context.values.countrycode + context.values.phone,
            home_country: context.values.homeCountry,
          };
          const url = `/custom_trip_request/${id}?primary_country=${primary_country}`;
          Api.put(url, params).then(({ json }) => {
            setTimeout(() => {
              history.push(`/completed?${urlParams.toString()}`);
            }, 10000);
            setPending(false);
          });
        } else {
        }
      });
    } else {
      setPending(false);
    }
  };

  useEffect(() => {
    ReactGa.send({ hitType: 'pageview', page: window.location.pathname + window.location.search, title: 'Contact Details page' });
    Api.get("/countries").then(({ json }) => {
      const countriesData = json?.data?.continents?.map(
        ({ continent_en_name, countries, continent_id }) => ({
          id: continent_id,
          label: continent_en_name,
          options: countries.map(
            ({ country_en_name: label, country_id: value }) => ({
              value,
              label,
            })
          ),
        })
      );
      setCountries(countriesData);
    });
  }, []);

  // useEffect(() => {
  //   console.log(selections)
  //   if (selections.error) {
  //     alert(selections.error.base)
  //   } else if (selections.id) {
  //     history.push('/completed')
  //   }
  // }, [selections])

  return (
    <>
      <Header isLastStep={false} isFirstStep={false} />
      <Dialog title="How do you prefer we reach you?">
        <span className={styles.title}>
          We'll use this to notify you when you have new messages from your Trip
          Designer.
        </span>
        <ContactDetailsForm formRef={formRef} countries={countries} />
        <Controls
          next={`/completed?${urlParams.toString()}`}
          nextDisabled={pending}
          onNextClick={onNextClick}
          buttonLabel={"Create Itinerary"}
        />
      </Dialog>
      <SelectionsSubmit />
    </>
  );
};

export default ContactDetails;
