import T from 'prop-types';

const locationShape = T.shape({
  id: T.string.isRequired,
  imageCategoryId: T.number,
  countryName: T.string.isRequired,
  tripCount: T.number.isRequired,
  selected: T.bool,
  imageUrl: T.string
});

export {
  locationShape
};
