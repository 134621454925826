import React, { useCallback } from 'react';
import T from 'prop-types';


import RadioInput from 'stride-core-ui/radio_input';
import styles from './styles';

const Option = ({
  title,
  description,
  name,
  data,
  select
}) => {
  const setType = useCallback(() => {
    select(name);
  });

  return (
    <div>
      <RadioInput
        onChange={ setType }
        name={ name }
        label={ title }
        checked={ data === name }
      />
      <div className={ styles.description }>{ description }</div>
    </div>
  );
};
Option.propTypes = {
  title: T.string.isRequired,
  description: T.string,
  name: T.string.isRequired,
  data: T.string,
  select: T.func.isRequired
};
Option.defaultProps = {
  description: undefined,
  data: undefined
};

export default Option;
