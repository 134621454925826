import React, { useEffect, useState, useRef, createRef } from "react";
import { MapContainer, TileLayer, Marker, FeatureGroup } from "react-leaflet";
import L from "leaflet";

const Map = ({
  coordinates,
  coordinatesCenter,
  customMarker,
  isMobile = false,
}) => {
  const getZoomLevel = (coordinates) => {
    const validCoordinates = coordinates.filter(
      (coord) =>
        Array.isArray(coord) &&
        coord.length === 2 &&
        coord[0] !== undefined &&
        coord[1] !== undefined
    );

    if (validCoordinates.length === 0) return 5; // Default zoom level

    const bounds = L.latLngBounds(
      validCoordinates.map((coord) => [coord[0], coord[1]])
    );

    const tempMapContainer = document.createElement("div");
    if (isMobile) {
      tempMapContainer.style.width = "100%"; // Set width to 100%
      tempMapContainer.style.height = "calc(60vh - 150px)"; // Set height to calc(100vh - 150px)
    } else {
      tempMapContainer.style.width = "50%"; // Set width to 100%
      tempMapContainer.style.height = "calc(100vh - 150px)"; // Set height to calc(100vh - 150px)
    }
    document.body.appendChild(tempMapContainer); // Append to the document body

    const map = L.map(tempMapContainer); // Create a temporary map instance
    map.fitBounds(bounds);
    const zoom = map.getZoom();
    map.remove(); // Clean up the temporary map instance
    document.body.removeChild(tempMapContainer); // Remove the temporary container

    return zoom || 5;
  };

  return (
    <MapContainer
      center={coordinatesCenter}
      zoom={getZoomLevel(coordinates)}
      scrollWheelZoom={false}
      style={{ height: "100%", width: "100%" }}>
      <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}" />
      <FeatureGroup>
        {coordinates?.map((marker, index) => (
          <Marker key={index} position={marker} icon={customMarker} />
        ))}
      </FeatureGroup>
    </MapContainer>
  );
};

export default Map;
