import { connect } from 'react-redux';
import Constants from 'config/constants';
import ContactDetailsForm from './form.jsx';

const mapStateToProps = ({ currentUser: { submitting, signupErrors, data } }) => ({
  submitting,
  errors: signupErrors,
  data
});

const mapDispatchToProps = (dispatch) => ({
  setContactDetails: (data) => {
    dispatch({
      type: Constants.Selections.CHANGE,
      subject: 'contactDetails',
      data
    });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactDetailsForm);
