import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import T from 'prop-types';
import BlockNavigation from 'stride-core-ui/block_navigation';
import ErrorMessage from 'components/error_message';
import Dialog from 'components/dialog';
import Controls from 'components/controls';
import { locationShape } from 'reducers/locations/locationsReducer';
import { useHistory } from 'react-router-dom';
// import AsyncSelect from 'react-select/async';
import Checkbox from 'stride-core-ui/checkbox';
import { ReactComponent as CheckMark } from 'assets/images/check.svg';
import { customSelectStyles, customSelectTheme } from 'components/select/config';
import { ValueContainer } from 'components/select';
import Api from '../../config/api';
import styles from './styles.module.scss';
import Button from '../../stride-core-ui/button';
import { ReactComponent as CloseIcon } from "assets/images/close.svg";
import Chip from 'components/chip';
import Header from 'components/header';
import ReactGA from 'react-ga4';

const BLOCKED_ROUTES = [{
  pathname: [
    '/who-is-traveling',
    '/when-will-you-be-traveling',
    '/what-kind-of-trip-are-you-looking-for',
    '/what-type-of-lodging-do-you-want',
    '/signin',
    '/home-country',
    '/budget',
    '/completed'
  ],
  exact: false
}];

const selectOptionsMobile = [
  {
    id: 1,
    label: 'Europe',
    options: [
      { value: 2, label: 'Italy' },
      { value: 3, label: 'Greece' },
      { value: 4, label: 'Spain' },
      { value: 5, label: 'France' },
      { value: 6, label: 'Germany' },
      { value: 7, label: 'United Kingdom' },
      { value: 8, label: 'Switzerland' },
      { value: 9, label: 'Austria' },
      { value: 10, label: 'Portugal' },
      { value: 11, label: 'Belgium' },
      { value: 12, label: 'Netherlands' },
      { value: 13, label: 'Norway' },
      { value: 14, label: 'Denmark' },
      { value: 15, label: 'Sweden' },
      { value: 16, label: 'Finland' },
      { value: 17, label: 'Hungary' },
      { value: 18, label: 'Poland' },
      { value: 19, label: 'Croatia' }
    ]
  },
  {
    id: 2,
    label: 'North America',
    options: [
      { value: 184, label: 'United States' },
      { value: 166, label: 'Suriname' },
      { value: 24, label: 'Canada' },
      { value: 68, label: 'Bahamas' }
    ]
  },
  {
    id: 3,
    label: 'Central America',
    options: [
      { value: 58, label: 'El Salvador' },
      { value: 89, label: 'Dominica' },
      { value: 101, label: 'Grenada' },
      { value: 105, label: 'Haiti' },
      { value: 152, label: 'Saint Vincent and the Grenadines' },
      { value: 172, label: 'Trinidad and Tobago' },
      { value: 29, label: 'Mexico' },
      { value: 51, label: 'Jamaica' },
      { value: 52, label: 'Cuba' },
      { value: 53, label: 'Costa Rica' },
      { value: 54, label: 'Panama' },
      { value: 55, label: 'Guatemala' },
      { value: 56, label: 'Belize' },
      { value: 57, label: 'Honduras' },
      { value: 59, label: 'Nicaragua' },
      { value: 65, label: 'Antigua and Barbuda' },
      { value: 71, label: 'Barbados' },
      { value: 90, label: 'Dominican Republic' },
      { value: 104, label: 'Guyana' },
      { value: 150, label: 'Saint Kitts and Nevis' },
      { value: 151, label: 'Saint Lucia' }
    ]
  },
  {
    id: 4,
    label: 'South America',
    options: [
      { value: 21, label: 'Argentina' },
      { value: 23, label: 'Brazil' },
      { value: 43, label: 'Chile' },
      { value: 44, label: 'Peru' },
      { value: 45, label: 'Colombia' },
      { value: 46, label: 'Venezuela' },
      { value: 47, label: 'Ecuador' },
      { value: 48, label: 'Bolivia' },
      { value: 49, label: 'Uruguay' },
      { value: 50, label: 'Paraguay' }
    ]
  },
  {
    id: 5,
    label: 'East Asia',
    options: [
      { value: 25, label: 'China' },
      { value: 28, label: 'Japan' },
      { value: 38, label: 'South Korea' },
      { value: 132, label: 'Mongolia' }
    ]
  },
  {
    id: 6,
    label: 'Southeast Asia',
    options: [
      { value: 77, label: 'Brunei' },
      { value: 91, label: 'East Timor' },
      { value: 27, label: 'Indonesia' },
      { value: 32, label: 'Thailand' },
      { value: 39, label: 'Malaysia' },
      { value: 40, label: 'Philippines' },
      { value: 41, label: 'Vietnam' },
      { value: 80, label: 'Cambodia' },
      { value: 116, label: 'Laos' },
      { value: 125, label: 'Maldives' },
      { value: 136, label: 'Myanmar' },
      { value: 139, label: 'Nepal' },
      { value: 159, label: 'Singapore' }
    ]
  },
  {
    id: 7,
    label: 'South Asia',
    options: [
      { value: 60, label: 'Afghanistan' },
      { value: 70, label: 'Bangladesh' },
      { value: 144, label: 'Pakistan' },
      { value: 168, label: 'Tajikistan' },
      { value: 26, label: 'India' },
      { value: 74, label: 'Bhutan' },
      { value: 164, label: 'Sri Lanka' },
      { value: 174, label: 'Turkmenistan' },
      { value: 179, label: 'Uzbekistan' }
    ]
  },
  {
    id: 8,
    label: 'West Asia',
    options: [
      { value: 36, label: 'Iran' },
      { value: 69, label: 'Bahrain' },
      { value: 107, label: 'Iraq' },
      { value: 114, label: 'Kuwait' },
      { value: 117, label: 'Lebanon' },
      { value: 167, label: 'Syria' },
      { value: 181, label: 'Yemen' },
      { value: 143, label: 'Oman' },
      { value: 33, label: 'Turkey' },
      { value: 35, label: 'Saudi Arabia' },
      { value: 37, label: 'Israel' },
      { value: 66, label: 'Armenia' },
      { value: 67, label: 'Azerbaijan' },
      { value: 99, label: 'Georgia' },
      { value: 110, label: 'Jordan' },
      { value: 111, label: 'Kazakhstan' },
      { value: 115, label: 'Kyrgyzstan' },
      { value: 147, label: 'Qatar' },
      { value: 178, label: 'United Arab Emirates' }
    ]
  },
  {
    id: 9,
    label: 'North Africa',
    options: [
      { value: 62, label: 'Algeria' },
      { value: 120, label: 'Libya' },
      { value: 31, label: 'Egypt' },
      { value: 134, label: 'Morocco' },
      { value: 173, label: 'Tunisia' }
    ]
  },
  {
    id: 10,
    label: 'Sub-Saharan Africa',
    options: [
      { value: 64, label: 'Angola' },
      { value: 73, label: 'Benin' },
      { value: 78, label: 'Burkina Faso' },
      { value: 81, label: 'Cameroon' },
      { value: 82, label: 'Cape Verde' },
      { value: 83, label: 'Central African Republic' },
      { value: 84, label: 'Chad' },
      { value: 85, label: 'Comoros' },
      { value: 86, label: 'Democratic Republic of the Congo' },
      { value: 87, label: 'Republic of the Congo' },
      { value: 88, label: 'Djibouti' },
      { value: 92, label: 'Equatorial Guinea' },
      { value: 93, label: 'Eritrea' },
      { value: 94, label: 'Eswatini' },
      { value: 95, label: 'Ethiopia' },
      { value: 97, label: 'Gabon' },
      { value: 98, label: 'Gambia' },
      { value: 102, label: 'Guinea' },
      { value: 103, label: 'Guinea-Bissau' },
      { value: 109, label: 'Ivory Coast' },
      { value: 118, label: 'Lesotho' },
      { value: 119, label: 'Liberia' },
      { value: 126, label: 'Mali' },
      { value: 128, label: 'Mauritania' },
      { value: 140, label: 'Niger' },
      { value: 141, label: 'Nigeria' },
      { value: 155, label: 'Sao Tome and Principe' },
      { value: 156, label: 'Senegal' },
      { value: 158, label: 'Sierra Leone' },
      { value: 163, label: 'Somalia' },
      { value: 165, label: 'Sudan' },
      { value: 170, label: 'Togo' },
      { value: 34, label: 'South Africa' },
      { value: 76, label: 'Botswana' },
      { value: 79, label: 'Burundi' },
      { value: 100, label: 'Ghana' },
      { value: 112, label: 'Kenya' },
      { value: 123, label: 'Madagascar' },
      { value: 124, label: 'Malawi' },
      { value: 129, label: 'Mauritius' },
      { value: 135, label: 'Mozambique' },
      { value: 137, label: 'Namibia' },
      { value: 149, label: 'Rwanda' },
      { value: 157, label: 'Seychelles' },
      { value: 169, label: 'Tanzania' },
      { value: 176, label: 'Uganda' },
      { value: 182, label: 'Zambia' },
      { value: 183, label: 'Zimbabwe' }
    ]
  },
  {
    id: 11,
    label: 'Eastern Europe',
    options: [
      { value: 20, label: 'Czech Republic' },
      { value: 63, label: 'Andorra' },
      { value: 72, label: 'Belarus' },
      { value: 154, label: 'San Marino' },
      { value: 30, label: 'Russia' },
      { value: 61, label: 'Albania' },
      { value: 75, label: 'Bosnia and Herzegovina' },
      { value: 106, label: 'Iceland' },
      { value: 108, label: 'Ireland' },
      { value: 121, label: 'Liechtenstein' },
      { value: 122, label: 'Luxembourg' },
      { value: 127, label: 'Malta' },
      { value: 131, label: 'Monaco' },
      { value: 133, label: 'Montenegro' },
      { value: 142, label: 'North Macedonia' },
      { value: 148, label: 'Romania' },
      { value: 160, label: 'Slovakia' },
      { value: 161, label: 'Slovenia' },
      { value: 177, label: 'Ukraine' }
    ]
  },
  {
    id: 12,
    label: 'Oceania',
    options: [
      { value: 113, label: 'Kiribati' },
      { value: 130, label: 'Micronesia' },
      { value: 138, label: 'Nauru' },
      { value: 145, label: 'Palau' },
      { value: 162, label: 'Solomon Islands' },
      { value: 175, label: 'Tuvalu' },
      { value: 22, label: 'Australia' },
      { value: 42, label: 'New Zealand' },
      { value: 96, label: 'Fiji' },
      { value: 146, label: 'Papua New Guinea' },
      { value: 153, label: 'Samoa' },
      { value: 171, label: 'Tonga' },
      { value: 180, label: 'Vanuatu' }
    ]
  }
];

const LocationsSearch = ({
  fetchLocations,
  collection,
  select,
  fetchingQuery,
  selectedLocations,
  moreSelectedLocations,
  reset,
  location
}) => {
  const history = useHistory();
  const [allMoreCountriesSelected, setAllMoreCountries] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [urlLocation] = useState(() => {
    const urlParams = new URLSearchParams(location.search || '');

    return urlParams.get('location') || '';
  });
  const [selectOptions, setSelectOptions] = useState(selectOptionsMobile);
  const [continent, setContinent] = useState([]);
  const [selectedContinent, setSelectedContinent] = useState([]);
  const [optionsLoading, setLoading] = useState(false);
  const [buttonIsEnabled, setButtonEnable] = useState(false);
  const onFavoriteCountryInputChange = (e) => {
    const values = Array.isArray(e)
      ? e.map(({ value: id, label: countryName }) => ({ id, countryName, slug: id }))
      : [];
    select(values, 'locations');
    // select(values.slice(1), 'moreLocations');

    // if (Array.isArray(e)) {
    //   const [firstValue, ...restValues] = e;
    //   select([firstValue], 'locations');
    //   select(restValues, 'moreLocations');
    // } else {
    //   select([], 'locations');
    //   select([], 'moreLocations');
    // }
    // const values = e !== null && e !== undefined ? [e].map(({ value: id, label: countryName }) => ({ id, countryName, slug: id })) : [];
    // select(values, 'locations');
  };

  const onCountryChange = (e) => {
    const values = Array.isArray(e)
      ? e.map(({ value: id, label: countryName }) => ({ id, countryName, slug: id }))
      : [];
    select(values, 'moreLocations');
  };

  const onChangeThis = (event, target) => {
    setButtonEnable(target);
    select(target ? 'Yes' : 'No', 'skipCountry');
  };

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search, title: 'Destination page' });

    setLoading(true);
    // Api.get('/countries')
    //   .then(({ json }) => {
    //     const countriesData = json?.data?.continents?.map(({
    //       continent_en_name,
    //       countries,
    //       continent_id
    //     }) => ({
    //       id: continent_id,
    //       label: continent_en_name,
    //       options: countries.map(({
    //         country_en_name: label,
    //         country_id: value
    //       }) => ({
    //         value,
    //         label
    //       }))
    //     }));
    //     setSelectOptions(countriesData);
    //   })
    //   .finally(() => {
    //     setLoading(false);
    //   });
    Api.get('/countries')
      .then(({ json }) => {
        const countriesData = json?.data?.continents?.map(({
          continent_en_name,
          countries,
          continent_id
        }) => ({
          id: continent_id,
          // label: continent_en_name,
          options: countries.map(({
            country_en_name: label,
            country_id: value
          }) => ({
            value,
            label
          }))
        }));
        setSelectOptions(countriesData);
      })
      .finally(() => {
        setLoading(false);
      });
    Api.get('/continents')
      .then(({ json }) => {
        setContinent(json?.data);
      });

    return reset;
  }, []);

  /**
   * If there is a 'location' param in the query, try to search this location
   * and check it for the user
   */
  useEffect(() => {
    if (urlLocation && urlLocation === fetchingQuery && collection && collection.length === 1) {
      const searchedLocation = collection.find(({ slug }) => slug === urlLocation);

      if (!searchedLocation) {
        return;
      }

      if (!selectedLocations.some((i) => i.slug === urlLocation)) {
        select(searchedLocation, 'locations');
      }
    }
  }, [urlLocation, fetchingQuery]);

  useEffect(() => {
    if (urlLocation) {
      fetchLocations(urlLocation);
    }
  }, [urlLocation]);

  const handleContinentClick = (value) => {
    setSelectedContinent((prevSelected) => prevSelected.includes(value) ? prevSelected.filter(item => item !== value) : [...prevSelected, value]);
    // setSelectedContinent((prevSelected) => [...prevSelected, value]);
  };

  function getOptions() {
    const filteredOptions = selectedContinent.length === 0 ? selectOptions : selectOptions.filter((continent) => selectedContinent.includes(continent.id));
    return filteredOptions;
  }

  useEffect(() => {
    if (urlLocation === 'ustoa') {
      const values = [{
        id: 'united-states',
        countryName: 'United States',
        slug: 'united-states'
      }];
      select(values, 'locations');
    }
  }, [urlLocation]);

  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <Header isLastStep={false} isFirstStep={false} />
      <Dialog title="Where do you want to go?">
        <span className={styles.title}>
          The perfect trip starts with the destination. So, spill the beans! What's on your bucket list for this trip?
        </span>
        <br />
        {screenSize.width >= 768 && (
          <div className={styles.selectedTags}>
            {continent.map(({
              continent_id: value,
              continent_en_name: label
            }) => (
              <Chip id={value} label={label} isActive={selectedContinent.includes(value)} handleClick={handleContinentClick} />
            ))}
          </div>
        )}
        <br />
        <div class={styles.selectContainer}>
          <Select
            closeMenuOnSelect={false}
            options={getOptions()}
            isLoading={optionsLoading}
            hideSelectedOptions={false}
            placeholder="Destination country"
            onChange={onFavoriteCountryInputChange}
            value={selectedLocations.map(({ id: value, countryName: label }) => ({ value, label }))}
            theme={customSelectTheme}
            styles={customSelectStyles}
            menuPortalTarget={document.body}
            menuPosition={'fixed'}
            isMulti
            isClearable
            isSearchable
          />
        </div>
        {!buttonIsEnabled && (
          <BlockNavigation
            to={BLOCKED_ROUTES}
            disabled={!buttonIsEnabled && selectedLocations.length !== 0}
            autoRelease={false}
          >
            <ErrorMessage>You can select maximum 5 countries</ErrorMessage>
          </BlockNavigation>
        )}
        {/* {selectedLocations.length > 0 && (
          <>
            <br />
            Add up to four points of interest you would like to visit on your trip
            <br />
            <br />
            {isOpen && (
              <div className={styles.confirmFlex}>
                <Button className={styles.confirm} size="sm">
                  confirm
                </Button >
              </div>
            )}
            <Select
              options={getOptions()}
              isMulti
              isLoading={optionsLoading}
              hideSelectedOptions={false}
              placeholder="Search / Dropdown list of pois"
              components={{ ValueContainer }}
              onChange={onCountryChange}
              value={moreSelectedLocations.map(({ id: value, countryName: label }) => ({ value, label }))}
              closeMenuOnSelect={false}
              theme={customSelectTheme}
              styles={customSelectStyles}
              onMenuOpen={() => setIsOpen(true)}
              onMenuClose={() => setIsOpen(false)}
            />
          </>
        )} */}

        <br />

        {/* <Checkbox
          onChange={onChangeThis}
          name="skipcountry"
          label="I'm not sure yet"
        /> */}

        <Controls
          // prev={`/${location.search}`}
          next={`/who-is-traveling${location.search}`}
          nextDisabled={!buttonIsEnabled && selectedLocations.length === 0}
          buttonLabel="Next"
        //  || moreSelectedLocations.length === 0}
        />
      </Dialog>
    </>
  );
};
LocationsSearch.propTypes = {
  fetchLocations: T.func.isRequired,
  fetchingQuery: T.string.isRequired,
  select: T.func.isRequired,
  // fetching: T.bool.isRequired,
  selectedLocations: T.arrayOf(locationShape).isRequired,
  collection: T.arrayOf(locationShape).isRequired,
  reset: T.func.isRequired,
  location: T.shape({ search: T.string })

};

LocationsSearch.defaultProps = {
  location: {}
};

export default LocationsSearch;
