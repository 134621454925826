import React, {
  PureComponent
} from 'react';
import T from 'prop-types';

import styles from './styles';

class Image extends PureComponent {
  static propTypes = {
    fetchLocationImage: T.func.isRequired,
    id: T.number,
    imageUrl: T.string
  }

  static defaultProps = {
    imageUrl: null,
    id: 0
  }

  componentDidMount() {
    const {
      id,
      fetchLocationImage,
      imageUrl
    } = this.props;

    if (imageUrl === undefined) fetchLocationImage({ id });
  }

  render() {
    const {
      imageUrl
    } = this.props;

    const style = { backgroundImage: `url(${imageUrl})` };

    return (
      <div
        className={ styles.root }
        style={ style }
      />
    );
  }
}

export default Image;
