import {
  toSnakeCase,
  toCamelCase
} from 'config/convert_case';
import { deserialize } from 'deserialize-json-api/src';
import axios from 'axios';

export function getCookie(cname) {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export function setCookie(cname, cvalue, exdays = 7) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cname}=${cvalue};${expires};path=/`;
}

const PREFIX = process.env.REACT_APP_API_ENDPOINT;
const PREFIXPHX = "http://127.0.0.1:5000/api";
const NETWORK_ERROR = { base: 'Network Error! Please try again later.' };
const SERVER_ERROR = { base: 'Oh no! Something bad happened. Please, make sure that you filled all the fields. I yes, come back later when we fixed that problem. Thanks.' };

const STATUSES_WITH_ACCEPTABLE_ERROR_MESSAGES = [401];

export const ACCESS_TOKEN_COOKIE = '_taccesstoken';

const buildHeaders = () => {
  const headers = new Headers();

  const cookie = getCookie(ACCESS_TOKEN_COOKIE);

  headers.append('Content-Type', 'application/json');
  headers.append('Accept', 'application/json');
  // headers.append('Authorization', '4ac8bd14-19d3-440e-8754-a3eff6a93e63');
  // headers.append('Access-Control-Allow-Origin', '*');
  // headers.append('Access-Control-Allow-Methods', 'GET,PUT,POST,DELETE,PATCH,OPTIONS');

  // if (cookie) {
  //   headers.append('Authorization', cookie);
  // }
  return headers;
};

const buildHeadersPHX = () => {
  const headers = new Headers();

  const cookie = getCookie(ACCESS_TOKEN_COOKIE);

  headers.append('Content-Type', 'application/json');
  headers.append('Accept', 'application/json');

  // if (cookie) {
  //   headers.append('Authorization', cookie);
  // }
  return headers;
};

async function get(path, params = {}, config) {
  const url = new URL(`${PREFIX}${path}`);
  Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]));


  const axiosConfig = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    url: url.toString(),
    // ...config
  };


  try {
    const response = await axios(axiosConfig);

    return {
      response,
      json: deserialize(response.data)
    };
  } catch (error) {
    if (error.response) {
      throw { statusText: error.response.statusText || 'Request failed' };
    } else {
      throw { statusText: 'Network Error' };
    }
  }
}

async function destroy(path) {
  const url = new URL(`${PREFIX}${path}`);
  const init = {
    method: 'DELETE',
    headers: buildHeaders()
  };

  const response = await fetch(url, init);

  if (!response.ok) {
    throw { statusText: 'Request failed' };
  }

  // if (!response.ok) {
  //   throw { statusText: response.statusText };
  // }

  const json = await response.json();

  return {
    response,
    json: toCamelCase(json)
  };
}

async function put(path, params = {}, opts = {}) {
  let url = path.includes('next-api/') ? `${window.location.origin}${path}` : `${PREFIX}${path}`;

  let response;

  try {
    response = await axios.put(url, params, {
      headers: buildHeaders(),
      ...opts
    });
  } catch (e) {
    if (e.response) {
      const { response } = e;
      const json = response.data;

      if (STATUSES_WITH_ACCEPTABLE_ERROR_MESSAGES.includes(response.status)) {
        throw { base: json.error };
      }

      throw toCamelCase(json.errors) || SERVER_ERROR;
    } else {
      throw NETWORK_ERROR;
    }
  }

  const json = response.data;

  return {
    response,
    json: toCamelCase(json)
  };
}

async function postTripGen(path, params = {}, opts = {}) {
  let url = path.includes('next-api/') ? `${window.location.origin}${path}` : `${PREFIX}${path}`;
  let response;

  try {
    response = await axios.post(url, params.params, {
      headers: buildHeaders(),
      ...opts
    });
  } catch (e) {
    if (e.response) {
      const { response } = e;
      const json = response.data;

      if (STATUSES_WITH_ACCEPTABLE_ERROR_MESSAGES.includes(response.status)) {
        throw { base: json.error };
      }

      throw toCamelCase(json.errors) || SERVER_ERROR;
    } else {
      throw NETWORK_ERROR;
    }
  }

  const json = response.data;

  return {
    response,
    json: toCamelCase(json)
  };
}
async function post(path, params = {}, opts = {}) {
  const scParams = toSnakeCase(params);
  let url = path.includes('next-api/') ? new URL(`${window.location.origin}${path}`) : new URL(`${PREFIX}${path}`);

  const req = new Request(url, {
    method: 'POST',
    headers: buildHeaders(),
    body: JSON.stringify({ ...scParams }),

    ...opts
  });

  let response;

  try {
    response = await fetch(req);
  } catch (e) {
    throw NETWORK_ERROR;
  }

  const json = await response.json();

  if (!response.ok) {
    if (STATUSES_WITH_ACCEPTABLE_ERROR_MESSAGES.includes(response.status)) {
      throw { base: json.error };
    }

    throw toCamelCase(json.errors) || SERVER_ERROR;
  }

  return {
    response,
    json: toCamelCase(json)
  };
}

async function postPHX(path, params = {}, opts = {}) {
  const scParams = toSnakeCase(params);
  let url = `https://base.travelstride.com/api${path}`;

  const req = new Request(url, {
    method: 'POST',
    headers: buildHeadersPHX(),
    body: JSON.stringify({ ...scParams }),

    ...opts
  });

  let response;

  try {
    response = await fetch(req);
  } catch (e) {
    throw NETWORK_ERROR;
  }

  const json = await response.json();

  if (!response.ok) {
    if (STATUSES_WITH_ACCEPTABLE_ERROR_MESSAGES.includes(response.status)) {
      throw { base: json.error };
    }

    throw toCamelCase(json.errors) || SERVER_ERROR;
  }

  return {
    response,
    json: toCamelCase(json)
  };
}

function patch(path, params = {}) {
  return post(path, params, { method: 'PATCH' });
}

const api = {
  get,
  destroy,
  post,
  patch,
  postPHX,
  postTripGen,
  put
};

export default api;
