import React from 'react';
import T from 'prop-types';

import styles from './styles';

const ErrorMessage = ({ children }) => <div className={ styles.error }>{ children }</div>;
ErrorMessage.propTypes = {
  children: T.node.isRequired
};

export default ErrorMessage;
