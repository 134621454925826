import React, { useEffect, useState } from 'react'
import T from 'prop-types';
import cs from 'classnames'

// import {
//   Redirect
// } from 'react-router';
import ServerAuthentication from 'stride-core-ui/server_authentication';
import BlockNavigation from 'stride-core-ui/block_navigation';
import ErrorMessage from 'components/error_message';

import Dialog from 'components/dialog';
import Controls from 'components/controls';

import {
  budgetShape
} from 'reducers/selections';

import BudgetForm from './form';
import styles from './styles';

const BLOCKED_ROUTES = [{
  pathname: [
    '/completed'
  ],
  exact: false
}];

function Marker({ value }) {
  return (
    <div
      className="absolute grid place-items-center"
      style={{
        width: "24px",
        height: "24px",
        top: "-40px",
        left: `${value * 3}px`,
      }}
    >
      <div className="relative w-full h-full text-sm">
        <div
          className="absolute grid place-items-center text-white font-semibold rounded-md"
          style={{
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            background: "#424e82",
            width: "50px",
            height: "30px",
          }}
        >
          ${value * 10}
        </div>

        <div
          className="absolute"
          style={{
            left: "50%",
            top: "150%",
            transform: "translate(-50%, -50%)",
            width: "15px",
            height: "30px",
          }}
        >
          <svg
            className="w-full"
            viewBox="0 0 22 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.874 26.6557C12.3017 28.5519 9.61685 28.5519 9.04458 26.6557L0.999992 0H20.9186L12.874 26.6557Z"
              fill="#424e82"
            />
          </svg>
        </div>
      </div>
    </div>
  );
}


const Prepend = ({ firstName }) => (
  <div className={styles.prepend}>
    <span className={styles.thanks}>
      Thanks, &nbsp;
      {firstName}
      .
    </span>
    &nbsp;
    Just a few more details.
  </div>
);
Prepend.propTypes = {
  firstName: T.string.isRequired
};

const BudgetDialog = ({
  data,
  select,
  locations,
  dates
}) => (
  <div>
    <Dialog
      // prepend={ <Prepend firstName={ firstName } /> }
      title="What is your budget per person?"
    >
      Good trip planners work hard to organize your trip, and their services are
      in high demand. It's important to have a realistic budget that your
      planner will feel comfortable working with, and which leaves room for them
      to be compensated.
      <br />
      <br />
      <div className={styles.includes}>
        <span>Budget includes: </span> hotels, guided tours/excursions,
        transferes, transportation, and some meals
        <br />
        <span>Budget does not include: </span> international flights and most
        meals during the travel itinerary
      </div>
      <br />
      <BudgetForm
        data={data}
        locations={locations}
        select={select}
        duration={dates.duration.value}
      />
      <br />
      <BlockNavigation
        to={BLOCKED_ROUTES}
        disabled={data.amount !== undefined}
        autoRelease={false}
      >
        <ErrorMessage>Please select a budget</ErrorMessage>
      </BlockNavigation>

      <Controls
        prev={`/what-type-of-lodging-do-you-want${location.search}`}
        next={`/tell-us-more-about-what-you-want-to-do${location.search}`}
        nextDisabled={!data.amount || !data.currency || !data.type}
      />

    </Dialog>

    {/* <SelectionsSubmit /> */}
  </div>
);
BudgetDialog.propTypes = {
  // firstName: T.string.isRequired,
  data: budgetShape.isRequired,
  select: T.func.isRequired
};

const renderBody = (done, authenticated, props) =>
  // if (!done) {
  //   return (
  //     <Dialog
  //       title="Authenticating..."
  //     >
  //       Please wait
  //     </Dialog>
  //   );
  // }
  //
  // if (!authenticated) {
  //   return (
  //     <Redirect
  //       to={{ pathname: '/signin',
  //       state: { from: props.location.pathname } }} // eslint-disable-line react/prop-types
  //     />
  //   );
  // }

  <BudgetDialog {...props} />;
const Budget = (props) => (
  <ServerAuthentication>
    {(done, authenticated) => renderBody(done, authenticated, props)}
  </ServerAuthentication>
);

export default Budget;
