import React from 'react';
import T from 'prop-types';
import cs from 'classnames';
import styles from './Button.module.scss';

const Button = ({
  children,
  kind,
  outlined,
  type,
  size,
  className: customClassName,
  as: Component,
  disabled,
  ...other
}) => {
  const className = cs(
    customClassName,
    styles[size],
    styles.root,
    styles[kind],
    { [styles.outlined]: outlined, [styles.disabled]: disabled });

  const elementProps = Component === 'button'
    ? { type }
    : { role: 'button' };

  return (
    <Component
      className={className}
      {...elementProps}
      {...other}
    >
      {children}
    </Component>
  );
};
Button.propTypes = {
  kind: T.oneOf(['primary', 'secondary', 'blank', 'sign-in']),
  type: T.string,
  children: T.node.isRequired,
  outlined: T.bool,
  as: T.oneOfType([T.elementType, T.func, T.element]),
  className: T.string,
  size: T.oneOf(['small', 'medium']),
  disabled: T.bool
};
Button.defaultProps = {
  kind: 'primary',
  type: 'button',
  outlined: false,
  as: 'button',
  className: '',
  size: 'medium',
  disabled: false
};

export default Button;
