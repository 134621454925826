import React from 'react';

import { ReactComponent as StrideLogoImage } from 'assets/images/travel_stride_logo.svg';
import styles from './styles';

const StrideLogo = () => (
  <a href="/">
    <StrideLogoImage className={ styles.strideLogoImage } />
  </a>
);

export default StrideLogo;
