
import {
  fromPersistedState
} from 'reducers/selections';

const VERSION = 5;


const isValid = (state) => VERSION === state.version
  && new Date(state.createdAt).getFullYear() === new Date().getFullYear()
  && new Date(state.createdAt).getMonth() === new Date().getMonth()
  && new Date(state.createdAt).getDate() === new Date().getDate();


export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('custom-trip-finder-state');
    if (serializedState === null) {
      return undefined;
    }

    const state = JSON.parse(serializedState);

    if (isValid(state)) {
      const out = {
        selections: fromPersistedState(state.data.selections)
      };

      return out;
    }

    return undefined;
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify({
      data: state,
      createdAt: new Date().toString(),
      version: VERSION
    });
    localStorage.setItem('custom-trip-finder-state', serializedState);
  } catch {
    // ignore write errors
  }
};
