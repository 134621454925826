import { connect } from 'react-redux';
import Constants from 'config/constants';
import Budget from './budget';


const mapStateToProps = ({ selections: { budget, locations, moreLocations, dates }, currentUser: { data } }) => ({
  data: budget,
  firstName: data.firstName,
  locations: locations.concat(moreLocations),
  dates
});

const mapDispatchToProps = (dispatch) => ({
  select: (data) => {
    dispatch({
      type: Constants.Selections.CHANGE,
      subject: 'budget',
      data
    });
  }
});


export default connect(mapStateToProps, mapDispatchToProps)(Budget);
