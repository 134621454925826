import {
  connect
} from 'react-redux';
import Constants from 'config/constants';
import SpecificWishes from './specific_wishes';

const mapStateToProps = ({ selections: { specificWishes } }) => ({
  data: specificWishes
});


const mapDispatchToProps = (dispatch) => ({
  select: (data) => {
    dispatch({
      type: Constants.Selections.CHANGE,
      subject: 'specificWishes',
      data
    });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(SpecificWishes);
