import '../../assets/variables.scss';

export const customSelectStyles = {
  groupHeading: (base) => ({
    ...base,
    fontWeight: 700,
    color: 'black',
    textTransform: 'unset',
    fontSize: 16
  }),
  placeholder: (base) => ({
    ...base,
    paddingLeft: 10
  }),
  input: (base) => ({
    ...base,
    paddingLeft: 5
  }),
  valueContainer: (base) => ({
    ...base,
    padding: 10
  }),
  // eslint-disable-next-line no-unused-vars
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    paddingLeft: 20,
    cursor: 'pointer'
  }),
  multiValue: (base) => ({
    ...base,
    marginLeft: 10
  }),
  control: (base) => ({
    ...base,
    borderColor: '#177A68',
  })
};

export const customSelectStylesContactDetails = {
  groupHeading: (base) => ({
    ...base,
    fontWeight: 700,
    color: 'black',
    textTransform: 'unset',
    fontSize: 16
  }),
  placeholder: (base) => ({
    ...base,
    paddingLeft: 30
  }),
  input: (base) => ({
    ...base,
    paddingLeft: 5
  }),
  valueContainer: (base) => ({
    ...base,
    padding: 10
  }),
  // eslint-disable-next-line no-unused-vars
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    paddingLeft: 20,
    cursor: 'pointer'
  }),
  multiValue: (base) => ({
    ...base,
    marginLeft: 10
  }),
  control: (base, { isFocused }) => ({
    ...base,
    borderColor: isFocused ? '#177A68' : '#7A7F89',
    borderRadius: 12,
    height: '3.125rem'
  })
};

export const customSelectTheme = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: '#1BB178',
    primary25: 'rgba(27, 177, 120, .2)',
    primary50: 'rgba(27, 177, 120, .5)'
  }
});
