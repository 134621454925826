import createReducer from 'config/create_reducer';
import Constants from 'config/constants';

export const getDefaultState = () => ({
  fetching: false,
  authenticated: false,
  data: {},
  signupErrors: {},
  signinErrors: {},
  submitting: false,
  fetched: false
});

const currentUser = createReducer(getDefaultState(), {
  [Constants.CurrentUser.FETCH](state) {
    return {
      ...state,
      fetching: true
    };
  },

  [Constants.CurrentUser.FETCH_FAILURE](state) {
    return {
      ...state,
      fetching: false,
      fetched: true
    };
  },

  [Constants.CurrentUser.FETCH_SUCCESS](state, action) {
    return {
      ...state,
      fetching: false,
      fetched: true,
      data: {
        email: action.data.email,
        firstName: action.data.firstName,
        lastName: action.data.lastName,
        userId: action.data.userId
      },
      authenticated: true
    };
  },

  [Constants.CurrentUser.SIGNUP](state) {
    return {
      ...state,
      signupErrors: {},
      submitting: true
    };
  },

  [Constants.CurrentUser.SIGNUP_SUCCESS](state, action) {
    return {
      ...state,
      submitting: false,
      authenticated: true,
      data: {
        email: action.data.email,
        firstName: action.data.firstName,
        lastName: action.data.lastName,
        userId: action.data.userId
      }
    };
  },

  [Constants.CurrentUser.SIGNUP_FAILURE](state, action) {
    return {
      ...state,
      submitting: false,
      signupErrors: action.errors
    };
  },

  [Constants.CurrentUser.SIGNIN](state) {
    return {
      ...state,
      signinErrors: {},
      submitting: true
    };
  },

  [Constants.CurrentUser.SIGNIN_SUCCESS](state, action) {
    return {
      ...state,
      submitting: false,
      authenticated: true,
      data: {
        email: action.data.email,
        firstName: action.data.firstName,
        lastName: action.data.lastName,
        userId: action.data.userId
      }
    };
  },

  [Constants.CurrentUser.SIGNIN_FAILURE](state, action) {
    return {
      ...state,
      submitting: false,
      signinErrors: action.errors
    };
  },

  [Constants.CurrentUser.SET_CONTACT_DETAILS](state, action) {
    const data = {
      email: action.data.email,
      firstName: action.data.firstname,
      lastName: action.data.lastname,
      phoneNumber: action.data.phone
    };

    return {
      ...state,
      data
    };
  }
});

export default currentUser;
export * from './prop_types';
