import React from 'react';
import T from 'prop-types';

import styles from './styles';


const Plugin = ({
  position,
  children,
  className: userClassName,
  size
}) => {
  const className = `'
    ${styles.plugin}
    ${position ? styles[position] : ''}
    ${styles[size]}
    ${userClassName}
  '`;

  return (
    <div className={ className }>
      { children }
    </div>
  );
};
Plugin.propTypes = {
  position: T.oneOf(['left', 'right']),
  children: T.oneOfType([T.node, T.func]).isRequired,
  className: T.string,
  size: T.oneOf(['medium'])
};
Plugin.defaultProps = {
  className: '',
  position: undefined,
  size: 'medium'
};

export default Plugin;
