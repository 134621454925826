import T from 'prop-types';
import Moment from 'moment';

export const specificWishesShape = T.shape({
  text: T.string.isRequired
});

export const travelersShape = T.shape({
  travelersNumber: T.shape({
    value: T.number,
    label: T.string
  }),
  ages: T.arrayOf(T.shape({
    name: T.string.isRequired,
    value: T.bool.isRequired
  }))
});

export const datesShape = T.shape({
  kind: T.oneOf(['exact', 'approx', 'duration']),
  startDate: T.instanceOf(Moment),
  endDate: T.instanceOf(Moment),
  departureMonth: T.shape({ label: T.string, value: T.string }),
  duration: T.shape({ label: T.string, value: T.number })
});

export const contactDetailsShape = T.shape({
  phoneNumber: T.string,
  country: T.shape({ value: T.string, label: T.string })
});

export const budgetShape = T.shape({
  amount: T.shape({ value: T.number, label: T.string }),
  type: T.oneOf(['strict', 'increase20', 'flexible']),
  planFlight: T.bool
});

export const tripTypeShape = T.oneOf([
  'not_decided',
  'independent',
  'partially_guided',
  'fully_guided'
]);

export const locationShape = T.shape({
  id: T.number.isRequired,
  imageCategoryId: T.number,
  countryName: T.string.isRequired,
  tripCount: T.number.isRequired,
  selected: T.bool,
  imageUrl: T.string
});

export const lodgingShape = T.arrayOf(T.shape({
  name: T.string.isRequired
}));

export const selectionsShape = T.shape({
  id: T.string,
  submitting: T.bool,
  error: T.shape({}),
  locations: T.arrayOf(locationShape).isRequired,
  specificWishes: specificWishesShape,
  travelers: travelersShape,
  dates: datesShape,
  tripType: tripTypeShape,
  contactDetails: contactDetailsShape,
  budget: budgetShape,
  lodging: lodgingShape
});
