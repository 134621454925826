import React, { useCallback, useEffect, useState } from 'react';
import T from 'prop-types';
import Api from 'config/api';
import Select from 'react-select';
import {
  budgetShape
} from 'reducers/selections';
import RadioInput from 'stride-core-ui/radio_input';
import styles from './styles';
import { currencyOptions, bugetOptions, budgetCustomStyles, currencyCustomStyles } from '../helper';
import Slider from 'components/slider/Slider';
import GlobeLoader from 'components/globe-loader/GlobeLoader';

const BUDGET_OPTIONS = bugetOptions();
const CURRENCY_OPTIONS = currencyOptions();

const customTheme = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: '#1BB178',
    primary25: 'rgba(27, 177, 120, .2)',
    primary50: 'rgba(27, 177, 120, .5)',
  }
});


const BudgetForm = ({ data, select, locations, duration }) => {
  const ids = locations.map(location => location.id);
  const [isLoading, setIsLoading] = useState(false);
  const [SliderMaxBudget, setSliderMaxBudget] = useState(0);
  const [SliderValue, setSliderValue] = useState(0);
  function calculatePercentage(total, percent) {
    return ((percent) / (total * duration)) * 100;
  }
  const onAmountChange = useCallback((amount) => {
    setSliderValue(calculatePercentage(SliderMaxBudget, amount.value));
    select({
      ...data,
      amount
    });
  }, [data]);
  const onCurrencyChange = useCallback((currency) => {
    select({
      ...data,
      currency
    });
  }, [data]);

  const onTypeChange = useCallback((type) => {
    select({
      ...data,
      type
    });
  }, [data]);

  useEffect(() => {
    setIsLoading(true);
    Api.post('/countries/maxBudgetVIP', {
      ids: ids
    })
      .then(({ json }) => {
        setSliderMaxBudget(json.data.avgMinBudgetVipPerDay);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    setSliderValue(calculatePercentage(SliderMaxBudget, data?.amount?.value));
  }, [SliderMaxBudget, data]);

  return (
    <>
      <GlobeLoader active={isLoading} />
      <div>
        <p>Lodging level base on your budget</p>
        <Slider initialValue={SliderValue} />
        <br />
        <p>
          With that in mind, please estimate your budget per person:
        </p>
        <div className={styles.dropdownWrapper}>
          <div>
            <Select
              value={data.amount}
              onChange={onAmountChange}
              options={BUDGET_OPTIONS}
              placeholder="Select"
              isSearchable={false}
              styles={budgetCustomStyles}
              theme={customTheme}
            />
          </div>
          <div className={styles.currencyBoxWrapper}>
            <Select
              value={data.currency}
              onChange={onCurrencyChange}
              options={CURRENCY_OPTIONS}
              placeholder="Select"
              isSearchable={false}
              styles={currencyCustomStyles}
              theme={customTheme}
            />
          </div>
        </div>
        {data.amount !== undefined && data.currency !== undefined
          ? (
            <div className={styles.grid}>
              <span className={styles.strictHeading}> How strict is this budget?</span>
              <br />
              <div className={styles.radio}>
                <RadioInput
                  name="flexible"
                  checked={data.type === 'flexible'}
                  onChange={onTypeChange}
                  label="It's just an estimate, I'm flexible depending on what's included in my trip and how much things cost in my chosen destination(s)"
                />
                <RadioInput
                  name="slightly flexible"
                  checked={data.type === 'slightly flexible'}
                  onChange={onTypeChange}
                  label="My budget is somewhat flexible
            "
                />
                <RadioInput
                  name="strict"
                  checked={data.type === 'strict'}
                  onChange={onTypeChange}
                  label="This is my absolute maximum budget"
                />

              </div>
            </div>
          ) : null}
      </div>
    </>
  );
};
BudgetForm.propTypes = {
  data: budgetShape.isRequired,
  select: T.func.isRequired
};

export default BudgetForm;
