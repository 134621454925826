import { connect } from 'react-redux';
import Constants from 'config/constants';
import ContactDetails from './ContactDetails';

const mapStateToProps = ({
  selections,
  currentUser
}) => ({
  selections,
  currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  submitSelections: (selections, currentUser) => {
    dispatch({
      type: Constants.Selections.SUBMIT,
      selections,
      currentUser
    });
  },
  updateId: (data) => {
    dispatch({
      type: Constants.Selections.CHANGE,
      subject: 'id',
      data
    });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactDetails);
