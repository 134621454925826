import React from "react";
import cs from "classnames";
import styles from "./LoaderQuotes.module.scss";

const LoaderQuotes = ({ author, quoteText }) => {
  const authorName = author;
  const quote = quoteText;

  const isLastStep =
    window.location.pathname == "/my-custom-trip/completed" ? true : false;

  const isContactStep =
    window.location.pathname == "/my-custom-trip/contact-details"
      ? true
      : false;

  const completedQuote = `Hang tight. We are generating your custom itinerary. This can take 30 seconds to 2 minutes. Or check your email later for a link if you prefer. Adventure awaits!`;

  const contactPage = `:Load time will take a few moments`;

  return (
    <div className={cs(styles.loaderQuotes)}>
      {isLastStep ? (
        <div className={styles.quote}>{completedQuote}</div>
      ) : (
        <>
          {isContactStep ? (
            <div className={styles.quote}>{contactPage}</div>
          ) : (
            <>
              <div className={styles.quote}>&quot;{quote}&quot;</div>
              <div className={styles.author}>{authorName}</div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default LoaderQuotes;
