/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import {
  takeEvery,
  call,
  put,
  takeLeading
} from 'redux-saga/effects';

import Api, { getCookie, ACCESS_TOKEN_COOKIE, setCookie } from 'config/api';

import Constants from 'config/constants';

const buildSignupErrors = (raw) => {
  const errors = {};
  if (raw.base) {
    return raw;
  }

  if (raw.error && raw.error.includes('emailisexist')) {
    errors.email = 'Email already exists';
  } else if (raw.error && raw.error.includes('invalidemail')) {
    errors.email = 'Invalid email!';
  }

  return errors;
};

function* signup({ data }) {
  try {
    const user = {
      email: data.email,
      password: data.password,
      firstName: data.firstname,
      passwordConfirmation: data.password
    };

    const {
      response,
      json
    } = yield call(Api.post, '/users', { user });

    if (response && response.ok) {
      yield put({
        type: Constants.CurrentUser.SIGNUP_SUCCESS,
        data: user
      });
    } else {
      throw json;
    }
  } catch (errors) {
    yield put({
      type: Constants.CurrentUser.SIGNUP_FAILURE,
      errors: buildSignupErrors(errors)
    });
  }
}

const buildSigninErrors = (raw) => {
  const errors = {};
  if (raw.base) {
    return raw;
  }

  errors.base = 'Wrong email or password';
  return errors;
};

function* signin({ data }) {
  try {
    const user = {
      email: data.email,
      password: data.password
    };

    const {
      response,
      json
    } = yield call(Api.post, '/users/sign_in', { user });
    // debugger;
    if (response && response.ok) {
      const token = json.data.attributes.accessToken;

      setCookie(ACCESS_TOKEN_COOKIE, token, 7);

      window.location.reload();
      // const myData = yield call(Api.get, '/v1/me', { user });
      //
      // yield put({
      //   type: Constants.CurrentUser.SIGNIN_SUCCESS,
      //   data: json
      // });
    } else {
      throw json;
    }
  } catch (errors) {
    yield put({
      type: Constants.CurrentUser.SIGNIN_FAILURE,
      errors: buildSigninErrors(errors)
    });
  }
}

function* fetchCurrentUser() {
  try {
    const cookieValue = getCookie('frontend');

    if (!cookieValue) {
      throw new Error();
    }
    const {
      response,
      json
    } = yield call(Api.get, '/v1/me', {}, { headers: { 'Magento-Authorization': cookieValue } });

    if (response && response.ok) {
      yield put({
        type: Constants.CurrentUser.FETCH_SUCCESS,
        data: json.data
      });
    } else {
      throw new Error();
    }
  } catch (e) {
    yield put({
      type: Constants.CurrentUser.FETCH_FAILURE
    });
  }
}

export default [
  takeEvery(Constants.CurrentUser.SIGNUP, signup),
  takeEvery(Constants.CurrentUser.SIGNIN, signin),
  takeLeading(Constants.CurrentUser.FETCH, fetchCurrentUser)
];
