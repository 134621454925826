import { all } from 'redux-saga/effects';

import locationsSagas from './locations';
import currentUserSagas from './current_user';
import selectionsSagas from './selections';

export default function* rootSaga() {
  yield all([
    ...locationsSagas,
    ...currentUserSagas,
    ...selectionsSagas
  ]);
}
