import React, { useEffect, useState, useRef } from "react";
import styles from "./styles";
import { ReactComponent as StarIcon } from "assets/images/star.svg";

const Slider = ({ initialValue, noStar, avg, min, max }) => {
  const [value, setValue] = useState(initialValue); // Assuming 12 is the initial value
  const [isDragging, setIsDragging] = useState(false);
  const sliderRef = useRef(null);
  const activeLineRef = useRef(null);
  const activeDotRef = useRef(null);
  const listItemsRef = useRef([]);

  const formatCurrency = (value) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  const updateSliderUI = (newValue) => {
    const max = sliderRef.current.getAttribute("max");
    const fullValue = Math.round((newValue * 100) / max);
    if (activeLineRef.current) {
      activeLineRef.current.style.width = `${fullValue}%`;
    }
    if (activeDotRef.current) {
      activeDotRef.current.style.left = `${fullValue}%`;
    }

    const vals = newValue / 10;
    listItemsRef.current.forEach((li, index) => {
      if (index <= vals) {
        li.classList.add(styles.active);
      } else {
        li.classList.remove(styles.active);
      }
    });
  };

  const handleSliderChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);
    updateSliderUI(newValue);
  };

  const startDrag = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  useEffect(() => {
    setValue(initialValue);
    updateSliderUI(initialValue);
  }, [initialValue]);

  const doDrag = (e) => {
    if (!isDragging) return;
    const sliderRect = sliderRef.current.getBoundingClientRect();
    const newValue = Math.max(
      0,
      Math.min(100, ((e.clientX - sliderRect.left) / sliderRect.width) * 100)
    );
    setValue(newValue.toFixed(0));
    updateSliderUI(newValue);
  };

  const stopDrag = () => {
    setIsDragging(false);
  };

  // useEffect(() => {
  //   updateSliderUI(value);
  //   document.addEventListener("mousemove", doDrag);
  //   document.addEventListener("mouseup", stopDrag);

  //   return () => {
  //     document.removeEventListener("mousemove", doDrag);
  //     document.removeEventListener("mouseup", stopDrag);
  //   };
  // }, [value, isDragging]); // Update effect dependencies

  return (
    <div className="flex justify-center">
      <div className="w-full">
        <div className={styles.rangeItem}>
          <div className={styles.rangeInput}>
            <input
              type="range"
              min="0"
              max="100"
              step="10"
              className="form-range"
              name="dataShared"
              value={value}
              onChange={handleSliderChange}
              ref={sliderRef}
            />
            <div className={styles.rangeLine}>
              <span ref={activeLineRef} className={styles.activeLine}></span>
            </div>
            <div className={styles.dotLine}>
              <span
                ref={activeDotRef}
                className={styles.activeDot}
                onMouseDown={startDrag}
                style={{ left: `${(value / 100) * 100}%` }} // Ensure dot position updates with state
              ></span>
            </div>
          </div>
          <ul
            className={styles.listInline}
            ref={(el) => (listItemsRef.current = el ? [...el.children] : [])}>
            <li className={styles.listInlineItem}>
              {!noStar ? (
                <span>
                  <StarIcon />
                  <StarIcon />
                  <StarIcon />
                  <StarIcon />
                </span>
              ) : (
                <span className={styles.price}>
                  {min && min > 0 && `Avg. ${formatCurrency(min)}`}
                </span>
              )}
            </li>
            <li className={styles.listInlineItem}>
              {!noStar ? (
                <span>
                  <StarIcon />
                  <StarIcon />
                  <StarIcon />
                  <StarIcon />
                </span>
              ) : (
                <span></span>
              )}
            </li>
            <li className={styles.listInlineItem}>
              {!noStar ? (
                <span>
                  <StarIcon />
                  <StarIcon />
                  <StarIcon />
                  <StarIcon />
                </span>
              ) : (
                <span className={styles.price}>
                  {avg && avg > 0 && `Avg. ${formatCurrency(avg)}`}
                </span>
              )}
            </li>
            <li className={styles.listInlineItem}>
              {!noStar ? (
                <span>
                  <StarIcon />
                  <StarIcon />
                  <StarIcon />
                  <StarIcon />
                </span>
              ) : (
                <span></span>
              )}
            </li>
            <li className={styles.listInlineItem}>
              {!noStar ? (
                <span>
                  <StarIcon />
                  <StarIcon />
                  <StarIcon />
                  <StarIcon />
                </span>
              ) : (
                <span className={styles.price}>
                  {max && max > 0 && `Avg. ${formatCurrency(max)}`}
                </span>
              )}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Slider;
