import ReactGA from 'react-ga';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

const emitGAEvent = (event) => {
  window.setTimeout(() => ReactGA.event(event), 0);
};

const initializeGA = () => {
  ReactGA.initialize(process.env.REACT_APP_GA_KEY, {
    debug: false
  });
  ReactGA.set({ contentGroup3: 'Rover AI Custom Trip Finder' });
};

class GoogleAnalytics extends Component {
  componentDidMount() {
    this.logPageChange(
      this.props.location.pathname,
      this.props.location.search
    );
  }

  componentDidUpdate({ location: prevLocation }) {
    const {
      location: {
        pathname,
        search
      }
    } = this.props;
    const isDifferentPathname = pathname !== prevLocation.pathname;
    const isDifferentSearch = search !== prevLocation.search;

    if (isDifferentPathname || isDifferentSearch) {
      this.logPageChange(pathname, search);
    }
  }

  logPageChange(pathname, search = '') {
    const page = pathname + search;
    const fullPage = `/my-custom-trip${page === '/' ? '' : page}`;

    if (page !== '/') {
      ReactGA.set({
        page: fullPage,
        ...this.props.options
      });
      ReactGA.pageview(fullPage);
    }
  }

  render() {
    return null;
  }
}

GoogleAnalytics.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types,react/require-default-props
  options: PropTypes.object
};

const RouteTracker = () =>
  <Route component={ GoogleAnalytics } />;

const initGoogleAnalytics = (options = {}) => {
  const isGAEnabled = !!process.env.REACT_APP_GA_KEY;

  if (isGAEnabled) {
    initializeGA(options);
  }

  return isGAEnabled;
};

export {
  GoogleAnalytics,
  RouteTracker,
  initGoogleAnalytics,
  emitGAEvent
};

export default initializeGA;
