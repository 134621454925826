import React, { PureComponent } from 'react';
import T from 'prop-types';
import Typed from 'react-typed';

import LazyRender from 'components/lazy_render';
import 'react-typed/dist/animatedCursor.css';

import ChatBot from './chat_bot';
import Message from './message';
import styles from './styles';

class Dialog extends PureComponent {
  static propTypes = {
    title: T.string.isRequired,
    children: T.node.isRequired,
    prepend: T.node
  }

  static defaultProps = {
    prepend: null
  }

  constructor() {
    super();

    this.state = { typingComplete: false };
  }

  componentWillUnmount() {
    this.typedRef.destroy();
  }

  onTypedComplete = () => {
    this.typedRef.cursor.classList.add(styles.disabled);
    this.setState({ typingComplete: true });
  }

  render() {
    const {
      title = `Your perfect <h1 className={styles.highlight}>trip</h1><br/>
               dreamed by <h1 className={styles.highlight}>you</h1><br/>
               designed by an <h1 className={styles.highlight}>expert</h1><br/>`,
      children,
      prepend
    } = this.props;

    return (
      <section className={styles.root}>
        {/* <ChatBot /> */}
        {prepend && prepend.length >= 1 &&
          (<span className={styles.prepend}>{prepend}</span>)
        }

        <Typed
          strings={[title]}
          className={styles.typed}
          typeSpeed={20}
          onComplete={this.onTypedComplete}
          typedRef={(el) => { this.typedRef = el; }}
        />

        <LazyRender visible={this.state.typingComplete}>
          {children}
        </LazyRender>
      </section>
    );
  }
}

export default Dialog;
