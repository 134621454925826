import React from 'react';
import T from 'prop-types';

import styles from './styles';


const Message = ({ children }) => (
  <section className={ styles.root }>
    { children }
  </section>
);
Message.propTypes = {
  children: T.node.isRequired
};

export default Message;
