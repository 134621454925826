import { connect } from 'react-redux';
import Constants from 'config/constants';

import Image from './image';

const mapDispatchToProps = (dispatch) => ({
  fetchLocationImage: (opts) => {
    dispatch({
      type: Constants.Locations.FETCH_IMAGE,
      opts
    });
  }
});


export default connect(undefined, mapDispatchToProps)(Image);
