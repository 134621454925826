import { connect } from 'react-redux';
import Constants from 'config/constants';
import SelectionsSubmit from './selections_submit';

const mapStateToProps = ({
  selections,
  currentUser
}) => ({
  selections,
  currentUser
});

const mapDispatchToProps = (dispatch) => ({
  submitSelections: (selections, currentUser) => {
    dispatch({
      type: Constants.Selections.SUBMIT,
      selections,
      currentUser
    });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectionsSubmit);
