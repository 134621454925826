const QuoteList = [
  {
    Author: 'Saint Augustine',
    Quote: 'The World is a book, and those who do not travel read only a page.',
  },
  {
    Author: 'Hans Christian Anderson',
    Quote: 'To travel is to live.',
  },
  {
    Author: 'Hilaire Belloc',
    Quote: 'We wander for distraction, but we travel for fulfilment.',
  },
  {
    Author: 'Martin Buber',
    Quote:
      'All journeys have secret destinations of which the traveler is unaware.',
  },
  {
    Author: 'Pauline Frommer',
    Quote: "You can't have a narrow mind and a thick passport.",
  },
  {
    Author: 'Roy Goodman',
    Quote: 'Remember happiness is a way of travel not a destination.',
  },
  {
    Author: 'Louis C.K.',
    Quote:
      "I'm bored' is a useless thing to say. You live in a great, big, vast world that you've seen none percent of.",
  },
  {
    Author: 'Dalai Lama',
    Quote: "Once a year, go someplace you've never been before.",
  },
  {
    Author: 'Mohammed',
    Quote: "Don't tell me how educated you are, tell me how much you traveled.",
  },
  {
    Author: 'Socrates',
    Quote: 'Wonder is the beginning of wisdom.',
  },
  {
    Author: 'Mark Twain',
    Quote: 'Travel is fatal to prejudice, bigotry, and narrow-mindedness.',
  },
  {
    Author: 'Lao Tzu',
    Quote: 'A journey of a thousand miles must begin with a single step.',
  },
  {
    Author: 'Unknown',
    Quote: 'Travel is the only thing you buy that makes you richer.',
  },
  {
    Author: 'Dr. Seuss',
    Quote: "Oh the places you'll go.",
  },
  {
    Author: 'Robert Frost',
    Quote:
      'Two roads diverged in a wood and I - I took the one less traveled by.',
  },
  {
    Author: 'Ibn Battuta',
    Quote:
      'Traveling - it leaves you speechless, then turns you into a storyteller.',
  },
  {
    Author: 'Tim Cahill',
    Quote: 'A journey is best measured in friends, rather than miles.',
  },
  {
    Author: 'Sir Richard Burton',
    Quote:
      'The gladdest moment in human life, me thinks, is a departure into unknown lands.',
  },
  {
    Author: 'Oliver Wendell Holmes',
    Quote:
      'A mind that is stretched by a new experience can never go back to its old dimensions.',
  },
  {
    Author: 'Roy M. Goodman',
    Quote: 'Remember that happiness is a way of travel - not a destination.',
  },
  {
    Author: 'Wendell Berry',
    Quote:
      // eslint-disable-next-line max-len
      'Nobody can discover the world for somebody else. Only when we discover it for ourselves does it become common ground and a common bond and we cease to be alone.',
  },
  {
    Author: 'Chief Seattle',
    Quote: 'Take only memories, leave only footprints.',
  },
]

export default QuoteList
