import React, { useEffect, useState } from 'react';
import T from 'prop-types';
import Moment from 'moment';
import cs from 'classnames';
import {
  Link
} from 'react-router-dom';
import { ReactComponent as CheckIcon } from 'assets/images/check_circle.svg';
import { ReactComponent as CheckIconUstoa } from 'assets/images/check_circle_ustoa.svg';
import {
  selectionsShape
} from 'reducers/selections';
import { withRouter } from 'react-router';
import Api from 'config/api';
import styles from './styles';
import { truncate } from 'config/helpers';

const gradient = 'linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.6) 100%)';

const urlParams = new URLSearchParams(location.search || '');
const urlLocation = urlParams.get('location') || '';

const CustomCheck = urlLocation === 'ustoa' ? CheckIconUstoa : CheckIcon



const Header = ({ locations, pathname }) => {
  const [imageUrl, setImageUrl] = useState(() => (locations.length > 0
    ? locations[0].imageUrl
    : undefined));

  useEffect(() => {
    const location = locations[0];

    if (imageUrl || !location?.id) {
      return;
    }

    // Api.get(`/v1/destinations/${location.id}`).then((result) => {
    //   if (result?.json?.data) {
    //     setImageUrl(result?.json?.data?.coverImage?.url);
    //   }
    // });
  }, []);

  const style = { background: `${gradient}, url(${imageUrl})` };
  return <div style={style} className={styles.header}>{pathname === 'completed' ? 'My Trip' : 'My Trip Summary'}</div>;
};
Header.propTypes = {
  locations: T.arrayOf(T.shape({
    imageUrl: T.string
  })).isRequired,
  pathname: T.string.isRequired
};

/* eslint-disable react/prop-types */
const locationsRenderer = ({ locations, moreLocations }) => (
  [...locations, ...moreLocations].length > 0
    ? (
      <div>
        {
          [...locations, ...moreLocations].map((location) => (
            <div className={styles.value} key={location.countryName}>
              <CustomCheck />
              <div className={styles.ellipsis}>{location.countryName}</div>
            </div>
          ))
        }
      </div>
    ) : null
);
/* eslint-enable react/prop-types */

/* eslint-disable react/prop-types */
const specificWishesRenderer = ({ specificWishes: { text } }) => (     //this step is removed
  text
    ? (
      <div className={styles.value}>
        <CustomCheck />
        <div className={styles.truncated}>{truncate(22, text)}</div>
      </div>
    ) : null
);
/* eslint-enable react/prop-types */

/* eslint-disable react/prop-types */
const travelersRenderer = ({ travelers: { travelersNumber } }) => (
  travelersNumber
    ? (
      <div className={styles.value}>
        <CustomCheck />
        {travelersNumber.label}
      </div>
    ) : null
);
/* eslint-enable react/prop-types */

/* eslint-disable react/prop-types */
const agesRenderer = ({ travelers: { ages } }) => (
  ages.some((age) => age.value === true) === true
    ? (
      <div>
        {
          ages.filter((age) => age.value === true).map((age) => (
            <div className={styles.value} key={age.name}>
              <CustomCheck />
              {age.name}
            </div>
          ))
        }
      </div>
    ) : null
);
/* eslint-enable react/prop-types */

/* eslint-disable react/prop-types */
const datesRenderer = ({ dates }) => {
  let duration;

  if (dates.startDate && dates.endDate) {
    duration = Moment.duration(dates.endDate.diff(dates.startDate)).humanize();
  } else if (dates.duration) {
    duration = dates.duration.label;
  }

  if (duration !== undefined) {
    return (
      <div>
        <div className={styles.value}>
          <CustomCheck />
          <div className={styles.ellipsis}>{duration}</div>
        </div>
      </div>
    );
  }

  return null;
};
/* eslint-enable react/prop-types */

const TRIP_TYPES = {
  not_decided: 'Not Decided',
  independent: 'Independent',
  partially_guided: 'Partially Guided',
  fully_guided: 'Fully Guided'
};

/* eslint-disable react/prop-types */
const tripTypeRenderer = ({ tripType }) => (
  tripType !== undefined
    ? (
      <div className={styles.value}>
        <CustomCheck />
        {TRIP_TYPES[tripType]}
      </div>
    ) : null
);
/* eslint-enable react/prop-types */

/* eslint-disable react/prop-types */
const lodgingRenderer = ({ lodging }) => (
  lodging.length > 0
    ? (
      <div>
        {
          lodging.map((item) => (
            <div className={cs(styles.value, styles.capitalize)} key={item.name}>
              <CustomCheck />
              {item.name}
            </div>
          ))
        }
      </div>
    ) : null
);
/* eslint-enable react/prop-types */

/* eslint-disable react/prop-types */
export const currencyChecker = (label) => {
  switch (label) {
    case "Euros":
      return "€"
    case "Pound sterling":
      return "£"
    case "Australian Dollars":
      return "AU$"
    case "New Zealand Dollars":
      return "NZ$"
    case "Canadian Dollars":
      return "CA$"
    case "Singapore Dollars":
      return "S$"
    default:
      return "$"
  }
}

const budgetRenderer = ({ budget }) => (
  budget.amount !== undefined && budget.currency !== undefined
    ? (
      <div>
        {[{ element: currencyChecker(budget.currency.label) + " " + budget.amount.label }, { element: budget.type }].map((el, ind) => {
          return <div className={styles.value} key={ind}>
            <CustomCheck />
            <div className={styles.ellipsis}>{el.element}</div>
          </div>
        })}
      </div>
    ) : null
);

const homeCountryRenderer = ({ contactDetails }) => (
  contactDetails && contactDetails.country && contactDetails.country.label ? (
    <div className={styles.value}>
      <CustomCheck />
      {contactDetails.country.label}
    </div>
  ) : null
);
/* eslint-enable react/prop-types */

const LEGEND_ITEMS = [
  {
    title: 'Destination',
    renderer: locationsRenderer,
    path: '/'
  },
  {
    title: 'People Traveling',
    renderer: travelersRenderer,
    path: '/who-is-traveling'
  },
  {
    title: 'Travelers ages',
    renderer: agesRenderer,
    path: '/who-is-traveling'
  },
  {
    title: 'Duration',
    renderer: datesRenderer,
    path: '/when-will-you-be-traveling'
  },
  {
    title: 'Trip Type',
    renderer: tripTypeRenderer,
    path: '/what-kind-of-trip-are-you-looking-for'
  },
  {
    title: 'Lodging',
    renderer: lodgingRenderer,
    path: '/what-type-of-lodging-do-you-want'
  },
  {
    title: 'Home country',
    renderer: homeCountryRenderer,
    path: '/home-country'
  },
  {
    title: 'Budget',
    renderer: budgetRenderer,
    path: '/budget'
  },
  {
    title: 'Trip Details',
    renderer: specificWishesRenderer,
    path: '/tell-us-more-about-what-you-want-to-do'
  }
];

const Template = ({ title, children, path }) => {
  if (children === null) {
    return null;
  }

  return (
    <div className={styles.template}>
      <div className={styles.title}>{title}</div>
      <Link to={path} className={styles.itemLink}>
        {children}
      </Link>
    </div>
  );
};
Template.propTypes = {
  title: T.string.isRequired,
  children: T.node,
  path: T.string.isRequired
};
Template.defaultProps = {
  children: null
};

const Legend = ({ data, location }) => (
  <div className={styles.root}>
    <Header locations={data.locations} pathname={location.pathname} />

    <div className={styles.legendItems}>
      {LEGEND_ITEMS.map((item) => (
        <Template
          key={item.title}
          title={item.title}
          path={(location.pathname === '/completed' ? '#' : item.path)}
        >
          {item.renderer(data)}
        </Template>
      ))}
    </div>
  </div>
);
Legend.propTypes = {
  data: selectionsShape.isRequired,
  location: T.shape({
    pathname: T.string.isRequired
  }).isRequired
};

export default withRouter(Legend);
