import React from 'react';
import T from 'prop-types';
import cs from 'classnames';

import styles from './ResultsGrid.module.scss';

const Grid = ({ children, className }) => (
  <div className={ cs(styles.root, className) }>
    { children }
  </div>
);

Grid.propTypes = {
  children: T.node.isRequired,
  className: T.string
};
Grid.defaultProps = {
  className: undefined
};

export default Grid;
export { default as Title } from './title';
export { default as Selectable } from './selectable';
