import React from 'react';
import T from 'prop-types';
import { travelersShape } from 'reducers/selections';

import Dialog from 'components/dialog';
import BlockNavigation from 'stride-core-ui/block_navigation';
import Controls from 'components/controls';
// import SelectionsSubmit from 'components/selections_submit';
import ErrorMessage from 'components/error_message';
import TravelersNumber from './travelers_number';
import TravelersAge from './travelers_age';
import styles from './styles';
import Header from 'components/header';
import ReactGA from 'react-ga4';

ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search, title: 'Travelers page' });


const Travelers = ({ data, select }) => (
  <>
    <Header isLastStep={false} isFirstStep={false} />
    <Dialog
      title="Who is traveling?"
    >
      <span className={styles.title}>
        Let us know your numbers, and don’t forget to include yourself!
      </span>
      <div class='flex flex-col mx-auto w-1/3'>
        <TravelersNumber
          data={data}
          select={select}
        />
      </div>

      {
        data.travelersNumber !== null
          ?
          <TravelersAge data={data} select={select} />
          : null
      }

      <Controls
        prev={`/where-do-you-want-to-go${location.search}`}
        next={`/when-will-you-be-traveling${location.search}`}
        nextDisabled={!data.travelersNumber || !data.ages.filter((i) => i.value).length}
        buttonLabel={'Next'}
      />
    </Dialog>
  </>
);
Travelers.propTypes = {
  data: travelersShape.isRequired,
  select: T.func.isRequired
};

export default Travelers;
