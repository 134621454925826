import React, { PureComponent } from 'react';
import T from 'prop-types';

import styles from './styles';

class LazyRender extends PureComponent {
  static propTypes = {
    children: T.node.isRequired,
    visible: T.bool.isRequired
  }

  constructor() {
    super();

    this.state = {
      visible: false,
      transitionEnd: false
    };

    this.updateRef = this.updateRef.bind(this);
    this.onTransitionEnd = this.onTransitionEnd.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.visible === false && this.props.visible === true) {
      this.show();
    }
  }

  componentWillUnmount() {
    this.rootRef.removeEventListener('transitionend', this.onTransitionEnd);
  }

  onTransitionEnd() {
    this.rootRef.removeEventListener('transitionend', this.onTransitionEnd);
    this.setState({ transitionEnd: true });
  }

  show() {
    this.rootRef.addEventListener('transitionend', this.onTransitionEnd);
    this.setState({ visible: true });
  }

  updateRef(ref) {
    this.rootRef = ref;
  }

  render() {
    const {
      children
    } = this.props;

    let maxHeight;
    let overflow = 'hidden';

    if (this.state.transitionEnd) {
      maxHeight = 'inherit';
      overflow = 'inherit';
    } else if (this.state.visible) {
      maxHeight = this.rootRef.scrollHeight;
    } else {
      maxHeight = 0;
    }

    const style = {
      maxHeight,
      opacity: (this.state.transitionEnd ? 1 : 0),
      overflow
    };
    const isFirstStep = window.location.pathname == "/my-custom-trip/" ? true : false;

    return (
      <div
        ref={this.updateRef}
        style={style}
        className={isFirstStep ? styles.rootFirstStep : styles.root}
      >
        {children}
      </div>
    );
  }
}

export default LazyRender;
