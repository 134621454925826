/* eslint-disable */
import React from 'react';
import cs from 'classnames';
import styles from './Checkbox.module.scss';

const Checkbox = ({
  isChecked,
  isDisabled,
  children,
  onChange,
  id = Math.random().toString(),
  name,
  ...other
}) => (
  <div className={ styles.root }>
    <label
      className={ cs(styles.label, { [styles.labelChecked]: isChecked }) }
      htmlFor={ id }
    >
      <span className={ styles.checkboxCustom } />
      {children}
    </label>
    <input
      { ...other }
      checked={ isChecked }
      className={ styles.default }
      disabled={ isDisabled }
      id={ id }
      name={ name }
      onChange={ onChange }
      type="checkbox"
    />
  </div>
);

export default Checkbox;
