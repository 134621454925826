import {
  connect
} from 'react-redux';
import Constants from 'config/constants';
import LocationsSearch from './locations_search';

// eslint-disable-next-line max-len
const mapStateToProps = ({ locations: { fetching, collection, fetchingQuery }, selections: { locations, moreLocations } }) => ({
  fetching,
  collection,
  fetchingQuery,
  selectedLocations: locations,
  moreSelectedLocations: moreLocations
});

const mapDispatchToProps = (dispatch) => ({
  fetchLocations: (query) => {
    dispatch({
      type: Constants.Locations.FETCH,
      query
    });
  },

  select: (data, subject) => {
    dispatch({
      type: Constants.Selections.CHANGE,
      subject,
      data
    });
  },

  reset: () => {
    dispatch({
      type: Constants.Locations.RESET
    });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationsSearch);
