const CURRENCIES = ['US Dollars', 'Australian Dollars', 'Canadian Dollars', 'Euros', 'Pound sterling', 'New Zealand Dollars', 'Singapore Dollars'];

export const budgetCustomStyles = {
  container: (provided) => ({
    ...provided,
    width: 275,
    margin: '1rem 0'
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: 'transparent'
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: state.isFocused ? '#1BB178' : provided.color
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: '1rem'
  })
};

export const currencyCustomStyles = {
  ...budgetCustomStyles,
  container: (provided) => ({
    ...provided,
    width: 220,
    margin: '1rem 0'
  })
};

export const bugetOptions = () => {
  const BUDGET_OPTIONS = [];
  for (let i = 2; i <= 40; i += 1) {
    BUDGET_OPTIONS.push({
      value: i * 500,
      label: `${new Intl.NumberFormat().format(i * 500)}`
    });
  }
  return BUDGET_OPTIONS;
};

export const currencyOptions = () => CURRENCIES.map((currency) => ({ value: currency, label: currency }));
