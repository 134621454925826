import React, { useCallback } from 'react';
import T from 'prop-types';
import { travelersShape } from 'reducers/selections';
import Select from 'react-select';
import { customSelectStyles, customSelectTheme } from 'components/select/config';

const OPTIONS = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 6, label: '6' },
  { value: 7, label: '7' },
  { value: 8, label: '8' },
  { value: 9, label: '9' },
  { value: 10, label: '10+' }
];


const TravelersNumber = ({ data, select }) => {
  const onChange = useCallback((selectedOption) => {
    select({
      ...data,
      travelersNumber: selectedOption
    });
  }, [data]);

  return (
    <Select
      value={data.travelersNumber}
      onChange={onChange}
      options={OPTIONS}
      placeholder="--"
      isSearchable={false}
      theme={customSelectTheme}
      styles={customSelectStyles}
    />
  );
};
TravelersNumber.propTypes = {
  data: travelersShape.isRequired,
  select: T.func.isRequired
};

export default TravelersNumber;
