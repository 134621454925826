import React, { useEffect, useState } from 'react';
import Container from 'components/container';
import ProgressBar from 'components/progress_bar';
import StrideLogo from './logo';
import PoweredByLogo from './poweredByLogo';
import { ReactComponent as GlobeIcon } from 'assets/images/globe.svg';
import { ReactComponent as InfoIcon } from 'assets/images/info.svg';
import styles from './styles';
import Api from '../../config/api';
import Button from 'stride-core-ui/button';
import { ReactComponent as UserIcon } from 'assets/images/user-circle.svg';
import cs from "classnames";

const STEPS = [
  {
    path: '/my-custom-trip/where-do-you-want-to-go',
  },
  {
    path: '/my-custom-trip/who-is-traveling',
  },
  {
    path: '/my-custom-trip/when-will-you-be-traveling',
  },
  {
    path: '/my-custom-trip/what-type-of-lodging-do-you-want',
  },
  {
    path: '/my-custom-trip/what-kind-of-trip-are-you-looking-for',
  },
  {
    path: '/my-custom-trip/tell-us-more-about-what-you-want-to-do',
  },
  {
    path: '/my-custom-trip/contact-details',
  }
];

const Header = () => {
  const [plmLogo, setPlmLogo] = useState(null);
  const [plmPath, setPlmPath] = useState(null);
  const [plmLocation, setPlmLocation] = useState(null);
  const urlParams = new URLSearchParams(location.search || '');
  const urlLocation = urlParams.get('location') || '';
  const currentPath = window.location.pathname;
  const [show, setShow] = useState(STEPS.some((step) => currentPath.includes(step.path)));

  // show should be false if the current path is not in STEPS
  // const show = STEPS.some((step) => currentPath.includes(step.path));

  // useEffect(() => {

  //   // Api.get(`/v1/dmo/${urlLocation}`)
  //   //   .then(({ json }) => {
  //   //     const logo = json?.data?.logoImage;
  //   //     let path = `https://www.travelstride.com/pl/${json?.data?.slug}`;
  //   //     if (urlLocation === 'ustoa') {
  //   //       path = 'https://ustoa.travelstride.com/';
  //   //     }
  //   //     setPlmLogo(logo);
  //   //     setPlmPath(path);

  // }, []);
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <header className={styles.root}>
      <div className={styles.brand}>
        {plmLogo ? (
          <>
            <a href={plmPath} >
              <img alt='' src={plmLogo.url} />
            </a>
            {urlLocation === 'ustoa' &&
              <div style={{ marginLeft: '1rem' }}>
                <PoweredByLogo />
              </div>
            }
          </>
        ) : (
          <div className={cs(styles.logoContainer, {
            [styles.w25]: show,
            ['w-1/2']: !show,
          })}>
            <StrideLogo />
          </div>
        )}
        <div className={cs('flex', {
          [styles.w25]: screenSize.width > 800,
          ['w-2/3']: screenSize.width <= 800,
        })}>

          <ProgressBar steps={STEPS} show={show} />
        </div>
        {screenSize.width >= 800 && (

          <div className={cs('flex justify-end gap-4 items-center', {
            [styles.w25]: show,
            ['w-1/2']: !show,
          })}>
            <GlobeIcon />
            {!show &&
              <InfoIcon />
            }
            <Button as='a' href='https://www.travelstride.com' kind={'outlined'}><UserIcon />Sign in</Button>
          </div>
        )}
        {(plmLogo && urlLocation !== 'ustoa') && <PoweredByLogo />}
      </div>
    </header>
  );
};

export default Header;
