import React, { useEffect, useState } from "react";
import T from "prop-types";
import { Link } from "react-router-dom";
import Button from "stride-core-ui/button";
import { ReactComponent as ArrowDown } from "assets/images/arrow_down.svg";
import styles from "./styles.module.scss";
import moment from "moment";
import cs from "classnames";

const Day = ({
  id,
  start_city,
  end_city,
  startDate,
  title,
  description,
  highlights,
  imageUrl,
}) => {
  const [isOpened, setOpened] = useState(id === 1);
  const endDate = moment(startDate, "MM/DD/YYYY")
    .add(1, "days")
    .format("MM/DD/YYYY");
  const handleOpened = () => {
    setOpened(!isOpened);
  };

  const getTitle = (isOpened) => {
    if (isOpened) {
      return (
        <>
          Day {id} - <span>{title}</span>
        </>
      );
    }

    return (
      <>
        Day {id} - {title}
      </>
    );
  };

  const getCity = (start_city, end_city) => {
    if (start_city == end_city) {
      return <>{start_city}</>;
    }

    return (
      <>
        {start_city} to <span>{end_city}</span>
      </>
    );
  };

  return (
    <div className={styles.root}>
      <div
        className={cs(styles.dayContainer, {
          [styles.open]: isOpened,
        })}
        style={imageUrl ? { backgroundImage: `url(${imageUrl})` } : {}}
        onClick={handleOpened}>
        <div className={styles.dayTitle}>
          <span className={styles.dayNumber}>{id}</span>
          <div className="flex flex-col w-2/3">
            <span className={styles.location}>{getTitle(isOpened)}</span>
            <span>{moment(startDate, "MM/DD/YYYY").format("MMM D")}</span>
          </div>
          <div className="flex flex-col justify-end w-1/3 text-end">
            <span className={styles.svg} onClick={handleOpened}>
              <ArrowDown className={isOpened ? styles.rotated : ""} />
            </span>
            <span className={styles.fromTo}>
              {getCity(start_city, end_city)}
            </span>
          </div>
        </div>
      </div>
      {isOpened && (
        <div className={styles.content}>
          <div className={styles.dayContent}>
            <div className={styles.imageContatiner}>
              <ul className={styles.list}>
                {highlights &&
                  highlights.map((highlight, index) => (
                    <li key={index}>{highlight}</li>
                  ))}
              </ul>
              <div className={styles.image}>
                <img src={imageUrl} />
              </div>
            </div>
            <span className={styles.description}>{description}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default Day;
