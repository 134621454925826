import React from 'react';
import T from 'prop-types';
import cs from 'classnames';
import styles from './styles';

const Container = ({ children, isLastStep }) => (
  <div className={cs({ [styles.container]: isLastStep, [styles.root]: !isLastStep })}>
    {children}
  </div>
);
Container.propTypes = {
  children: T.node.isRequired
};

export default Container;
