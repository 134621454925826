import React, { useState, useEffect } from 'react';
import T from 'prop-types';

import {
  tripTypeShape
} from 'reducers/selections';
import BlockNavigation from 'stride-core-ui/block_navigation';
import ErrorMessage from 'components/error_message';
import Dialog from 'components/dialog';
import Controls from 'components/controls';
// import SelectionsSubmit from 'components/selections_submit';
import Option from './option';

import styles from './styles';
import Slider from 'components/slider/Slider';
import Card from 'components/card';
import Header from 'components/header';
import independentIcon from "assets/images/independet.png";
import mixedIcon from "assets/images/mixed.png";
import groupIcon from "assets/images/group.png";
import privateIcon from "assets/images/private.png";
import image from 'routes/locations_search/image';
import ReactGa from 'react-ga4';

const BLOCKED_ROUTES = [{
  pathname: [
    '/signin',
    '/home-country',
    '/budget',
    '/completed'
  ],
  exact: false
}];

const formatCurrency = (value) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value);
};

const TripType = ({
  data,
  select,
  lodging,
  dates,
  travelers,
  budget,
  updateAmount
}) => {
  const budgetAmount = budget.amount;
  const duration = () => {
    let duration = 0;

    if (dates.kind === 'exact' && dates.startDate && dates.endDate) {
      duration = dates.startDate && dates.endDate ? dates.endDate.diff(dates.startDate, 'days') : '';
    }
    if (dates.kind === 'approx' && dates.duration) {
      duration = dates.duration.value;
    }
    if (dates.kind === 'duration' && dates.duration) {
      duration = dates.duration.value;
    }
    return duration;
  };
  const OPTIONS = [{
    name: 'independent',
    title: 'Independent',
    description: 'Explore on your own, at your own pace.',
    image: independentIcon,
    price: (((budgetAmount / travelers.travelersNumber.value) / duration()) * 0.8)
  }, {
    name: 'shared_tour',
    title: 'Group Tours',
    description: 'Spend most of your days joining small group tours, bust still have some free time.',
    image: groupIcon,
    price: ((budgetAmount / travelers.travelersNumber.value) / duration())
  }, {
    name: 'partially_guided',
    title: 'Mixed',
    description: 'Group or private guides for some highlights, but much of your time independent',
    image: mixedIcon,
    price: (((budgetAmount / travelers.travelersNumber.value) / duration()) * 0.95)
  }, {
    name: 'fully_guided',
    title: 'Private Guided',
    description: 'Have a local private guide whenever possible.',
    image: privateIcon,
    price: (((budgetAmount / travelers.travelersNumber.value) / duration()) * 1.2)
  }];



  const getTitle = (dates, lodging, travelers) => {
    let duration = '';
    if (dates.kind === 'exact' && dates.startDate && dates.endDate) {
      duration = dates.startDate && dates.endDate ? dates.endDate.diff(dates.startDate, 'days') : '';
    }
    if (dates.kind === 'approx' && dates.duration) {
      duration = dates.duration.value;
    }
    if (dates.kind === 'duration' && dates.duration) {
      duration = dates.duration.value;
    }
    let stars = ''
    if (lodging == 'Standard') {
      stars = 'standard';
    }
    if (lodging == 'Premium') {
      stars = 'premium';
    }
    if (lodging == 'Luxury') {
      stars = 'luxury';
    }
    let numTravelers = ''
    if (travelers && travelers.travelersNumber) {
      numTravelers = travelers.travelersNumber.value;
    }
    let accommodations = OPTIONS.find(option => option.name === data)?.title;

    return <><span>{numTravelers} travelers</span> for <span>{duration} days</span> staying in <span>{stars}</span> accommodations for <span>{accommodations}</span></>;
  };

  useEffect(() => {
    ReactGa.send({ hitType: 'pageview', page: window.location.pathname + window.location.search, title: 'Trip Type page' });
  }, []);

  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });


  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getPriceRange = () => {
    let accommodations = OPTIONS.find(option => option.name === data)?.title;
    if (accommodations == 'Independent') {
      return `${formatCurrency((budgetAmount) * 0.6)} - ${formatCurrency((budgetAmount) * 0.8)}`;
    }
    if (accommodations == 'Group Tours') {
      return `${formatCurrency((budgetAmount) * 0.8)} - ${formatCurrency((budgetAmount) * 1)}`;
    }
    if (accommodations == 'Mixed') {
      return `${formatCurrency((budgetAmount) * 0.6)} - ${formatCurrency((budgetAmount) * 1)}`;
    }
    if (accommodations == 'Private Guided') {
      return `${formatCurrency((budgetAmount) * 1)} - ${formatCurrency((budgetAmount) * 1.2)}`;
    }
  };
  // const updateBudget = (data) => {
  //   let amount = budgetAmount;
  //   if (data === 'fully_guided') {
  //     amount = budgetAmount * 1.3;
  //   }
  //   if (data === 'shared_tour') {
  //     amount = budgetAmount * 1.1;
  //   }
  //   if (data === 'partially_guided') {
  //     amount = budgetAmount * 1.2;
  //   }
  //   if (amount > 0) {
  //     updateAmount({
  //       ...budget,
  //       amount
  //     });
  //   }
  // };

  return (
    <>
      <Header isLastStep={false} isFirstStep={false} />
      <Dialog
        title="What kind of trip are you looking for?"
      >
        <span className={styles.title}>
          {getTitle(dates, lodging, travelers)}
        </span>
        {/* {screenSize.width >= 730 ? (
          <div class='w-3/4 mx-auto'>
            <Slider
              initialValue={sliderValue}
              noStar
              avg={budgetAmount * 1.2}
              min={budgetAmount}
              max={budgetAmount * 1.3}
              setSliderValue={setSliderValue}
            />
          </div>
        ) : (
          <div className={styles.avg}>
            Avg: {formatCurrency(budgetAmount * 1.2)}
          </div>
        )} */}

        <div class='w-2/4 mx-auto text-center'>
          {/* <Slider initialValue={sliderValue} noStar avg={budgetAmount * 1.2} min={budgetAmount} max={budgetAmount * 1.3} /> */}
          <span className={styles.price}>Total trip price estimate: {getPriceRange()}</span>
        </div>
        <div className={styles.cardContainer}>
          {
            OPTIONS.map((item) => {
              const selected = data === item.name;
              return (
                <Card data={item.name} image={item.image} name={item.title} onSelect={select} isActive={selected} description={item.description} price={item.price} />
              );
            })
          }
        </div>
        {screenSize.width >= 400 && (
          <span className={styles.note}>
            Note: These are rough averages based on typical costs in various cities, factoring in the time of year, seasons, and other variables.
          </span>
        )}

        <BlockNavigation
          to={BLOCKED_ROUTES}
          disabled={data !== undefined}
          autoRelease={false}
        >
          <ErrorMessage>Please select one of the options above</ErrorMessage>
        </BlockNavigation>

        <Controls
          prev={`/what-type-of-lodging-do-you-want${location.search}`}
          next={`/tell-us-more-about-what-you-want-to-do${location.search}`}
          buttonLabel={'Next'}
        // onNextClick={() => updateBudget(data)}
        />
      </Dialog>
    </>

  )
};
TripType.propTypes = {
  select: T.func.isRequired,
  data: tripTypeShape
};
TripType.defaultProps = {
  data: undefined
};

export default TripType;
