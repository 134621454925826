import React, { PureComponent } from 'react';
import T from 'prop-types';
import { Prompt } from 'react-router-dom';
import { matchPath, withRouter } from 'react-router';


class BlockNavigation extends PureComponent {
  static propTypes = {
    to: T.arrayOf(T.shape({
      path: T.string,
      exact: T.bool
    })).isRequired,
    children: T.oneOfType([T.func, T.node]).isRequired,
    history: T.shape({
      push: T.func.isRequired
    }).isRequired,
    disabled: T.bool,
    autoRelease: T.bool
  }

  static defaultProps = {
    disabled: false,
    autoRelease: true
  }

  constructor(props) {
    super(props);

    this.state = {
      blocked: !this.props.disabled,
      lastBlockedLocation: undefined
    };

    this.handleBlockedNavigation = this.handleBlockedNavigation.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.disabled !== this.props.disabled) {
      this.updateDisabledState();
    }

    if (this.props.autoRelease && prevState.blocked === true && this.state.blocked === false) {
      this.props.history.push(this.state.lastBlockedLocation);
    }
  }

  onCancel() {
    this.setState({
      lastBlockedLocation: undefined
    });
  }

  onConfirm() {
    this.setState({
      blocked: false
    });
  }

  updateDisabledState() {
    this.setState({
      blocked: !this.props.disabled
    });
  }

  handleBlockedNavigation(lastBlockedLocation) {
    const {
      to
    } = this.props;

    const shouldBlock = to.some((location) => matchPath(
      lastBlockedLocation.pathname,
      { path: location.pathname, exact: location.exact }
    ) !== null);

    this.setState({
      blocked: shouldBlock,
      lastBlockedLocation: shouldBlock ? lastBlockedLocation : undefined
    });

    return !shouldBlock;
  }

  renderChildren() {
    const {
      children
    } = this.props;

    const props = {
      onConfirm: this.onConfirm,
      onCancel: this.onCancel
    };

    return typeof children === 'function'
      ? this.props.children(props)
      : React.cloneElement(children, props);
  }

  render() {
    return (
      <>
        {
          !this.props.disabled && this.state.lastBlockedLocation !== undefined
            ? this.renderChildren()
            : null
        }
        <Prompt
          when={ this.state.blocked }
          message={ this.handleBlockedNavigation }
        />
      </>
    );
  }
}

export default withRouter(BlockNavigation);
