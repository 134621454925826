import React, { useEffect, useState } from "react";
import T from "prop-types";
import { Link } from "react-router-dom";
import Button from "stride-core-ui/button";
import { ReactComponent as ArrowDown } from "assets/images/arrow_down.svg";
import { ReactComponent as CloseIcon } from "assets/images/close.svg";
import cs from "classnames";
import styles from "./styles.module.scss";

const Chip = ({ id, label, isActive, handleClick = () => {} }) => {
  return (
    <div className={styles.tag}>
      <div
        className={cs(styles.innerFlex, {
          [styles.active]: isActive,
        })}
        onClick={() => handleClick(id)}>
        <span>{label}</span>
        <CloseIcon />
      </div>
    </div>
  );
};

export default Chip;
