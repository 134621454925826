import {
  connect
} from 'react-redux';
import Constants from 'config/constants';
import Travelers from './travelers';

const mapStateToProps = ({ selections: { travelers } }) => ({
  data: travelers
});


const mapDispatchToProps = (dispatch) => ({
  select: (data) => {
    dispatch({
      type: Constants.Selections.CHANGE,
      subject: 'travelers',
      data
    });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Travelers);
