/* eslint-disable no-nested-ternary */
import { call, put, takeEvery } from 'redux-saga/effects';
import moment from 'moment';
import Api from 'config/api';
import Constants from 'config/constants';
import Cookies from 'js-cookie';
import { emitGTMEvent } from 'config/gtm';
import { emitGAEvent } from 'config/ga';
import { toSnakeCase } from '../config/convert_case';
import { currencyChecker } from '../components/sidebar/legend/legend';

export const COOKIE_NAME = 'frontend';
const urlParams = new URLSearchParams(window.location.search);
const urlLocation = urlParams.get('location');
const websiteCode = (window.location.hostname).includes('ustoa') || urlLocation === 'ustoa' ? 'ustoa' : urlLocation || 'travelstride';

const lodging = [
  { id: 96, name: "Standard" },
  { id: 95, name: "Premium" },
  { id: 94, name: "Luxury" }
];

const buildSelectionsParams = (data) => {
  // eslint-disable-next-line max-len
  const date = data.dates.departureMonth && data.dates.departureMonth.value
    ? moment(data.dates.departureMonth.value)
    : undefined;
  const out = {
    // primaryCountry: data.locations && data.locations[0] ? data.locations[0].countryName : '',
    // ages: data.travelers.ages.filter((i) => i.value).map((age) => age.name),
    // travelersNumber: data.travelers.travelersNumber,
    // startDate: data.dates.startDate
    //   ? data.dates.startDate.format('YYYY-MM-DD')
    //   : date
    //     ? date.format('YYYY-MM-DD')
    //     : undefined,
    // endDate: data.dates.startDate
    //   ? data.dates.endDate.format('YYYY-MM-DD')
    //   : date
    //     ? date.add(data.dates.duration.value, 'days').format('YYYY-MM-DD')
    //     : undefined,
    // duration: data.dates.duration ? data.dates.duration.value : undefined,
    // tripType: data.tripType,
    // lodgingLevels: data.lodging.map((l) => l.name),
    // budget: data.budget.amount
    //   ? data.budget.amount.toString()
    //   : undefined,
    // details: data.specificWishes.text,
    // locationSlugs: data.moreLocations.length ? data.moreLocations.map((l) => l.slug) : '',
    // skip_destination: data.skipDestination,
    // website_code: websiteCode,
    preferred_contact_method: data.contactDetails.contact_preferences,
    home_country: data.contactDetails.homeCountry,
    first_name: data.contactDetails.firstname,
    last_name: data.contactDetails.lastname,
    email: data.contactDetails.email,
    phone_number: data.contactDetails.phone,
    // countryCode: data.contactDetails.countrycode
  }

  // eslint-disable-next-line no-unused-vars
  return Object.fromEntries(Object.entries(out).filter(([k, v]) => !!v));
};

const buildPHXParams = (data) => {
  // eslint-disable-next-line max-len
  const lodging_level = lodging.find((l) => l.name === data.lodging).id;
  const date = data.dates.departureMonth && data.dates.departureMonth.value
    ? moment(data.dates.departureMonth.value)
    : undefined;
  const formatCountryName = (countryName) => countryName.toLowerCase().replace(/ /g, '-');
  const out = {
    customTripRequest: {
      locationSlugs: data.locations && data.locations[1] ? data.locations.slice(1).map((l) => formatCountryName(l.countryName)) : '',
      primaryCountrySlug: data.locations && data.locations[0] ? formatCountryName(data.locations[0].countryName) : '',
      travelersNumber: data.travelers.travelersNumber.value,
      ages: data.travelers.ages.filter((i) => i.value).map((age) => age.name),
      tripType: data.tripType,
      startDate: data.dates.startDate
        ? data.dates.startDate.format('YYYY-MM-DD')
        : date
          ? date.format('YYYY-MM-DD')
          : undefined,
      endDate: data.dates.startDate
        ? data.dates.endDate.format('YYYY-MM-DD')
        : date
          ? date.add(data.dates.duration.value, 'days').format('YYYY-MM-DD')
          : undefined,
      duration: data.dates.duration ? data.dates.duration.value : undefined,
      lodgingLevels: [lodging_level],
      budget: data.budget.amount
        ? data.budget.amount.toString()
        : undefined,
      details: data.specificWishes.text,
      website_code: "CTR AI",
      preferred_contact_method: data.contactDetails.contact_preferences,
      home_country_slug: formatCountryName(data.contactDetails.homeCountry),
      home_country: formatCountryName(data.contactDetails.homeCountry),
      first_name: data.contactDetails.firstname,
      last_name: data.contactDetails.lastname,
      email: data.contactDetails.email,
      phone_number: data.contactDetails.phone,
      countryCode: data.contactDetails.countrycode
    }
  }

  // eslint-disable-next-line no-unused-vars
  return Object.fromEntries(Object.entries(out).filter(([k, v]) => !!v));
};

const buildOrdersParams = (id, primary_country) => {
  const out = {
    item_id: id,
    primary_country: primary_country,
  };

  // eslint-disable-next-line no-unused-vars
  return Object.fromEntries(Object.entries(out).filter(([k, v]) => !!v));
};

const isValid = (selections) =>
  /* selections.locations.length !== 0
  && */ selections.contactDetails
  && selections.contactDetails.email;

function* submitSelections({ selections }) {
  // const newEntity = selections.id === undefined;
  const newEntity = true;

  if (!isValid(selections)) {
    return;
  }

  const id = localStorage.getItem("customTripResponse");
  const primary_country = selections.locations && selections.locations[0] ? selections.locations[0].countryName : '';
  const params = toSnakeCase(buildSelectionsParams(selections));
  const PHXParams = toSnakeCase(buildPHXParams(selections));
  const idParams = buildOrdersParams(id, primary_country);
  const url = `/custom_trip_request/${id}?primary_country=${primary_country}`;
  const PHXurl = '/v1/custom_trip_requests';
  try {
    const { PHXres } = yield call(newEntity ? Api.postPHX : Api.postPHX, PHXurl, PHXParams);
    // console.log(PHXres);
    const { json } = yield call(newEntity ? Api.put : Api.patch, url, params);
    const { res } = yield call(Api.postTripGen('/orders', {
      params: idParams
    }));
    yield put({
      type: Constants.Selections.SUBMIT_SUCCESS,
      data: json.data
    });
  } catch (error) {
    yield put({
      type: Constants.Selections.SUBMIT_FAILURE,
      error
    });
  }
}

export default [takeEvery(Constants.Selections.SUBMIT, submitSelections)];
