import TagManager from 'react-gtm-module';

const initializeGTM = () => {
  const tagManagerConfig = {
    gtmId: process.env.REACT_APP_GTM_ID
  };

  TagManager.initialize(tagManagerConfig);
};

const emitGTMEvent = (event) => {
  if (window.dataLayer) {
    window.setTimeout(() => window.dataLayer.push(event), 0);
  }
};

export default initializeGTM;
export { emitGTMEvent };
