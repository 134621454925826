import {
  connect
} from 'react-redux';
import Constants from 'config/constants';
import TripType from './trip_type';

const mapStateToProps = ({ selections: { tripType, lodging, dates, travelers, budget } }) => ({
  data: tripType,
  lodging,
  dates,
  travelers,
  budget
});


const mapDispatchToProps = (dispatch) => ({
  select: (data) => {
    dispatch({
      type: Constants.Selections.CHANGE,
      subject: 'tripType',
      data
    });
  },
  updateAmount: (data) => {
    dispatch({
      type: Constants.Selections.CHANGE,
      subject: 'budget',
      data
    });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(TripType);
