import React from 'react';
import { ReactComponent as StrideLogoImage } from 'assets/images/travel_stride_logo.svg';
import styles from './styles';

const PoweredByLogo = () => (
  <div className={styles.powered}>
      <a href="/">
        <span>Powered by</span>
        <div className={styles.poweredLogo}>
          <StrideLogoImage />
        </div>
      </a>
  </div>
);

export default PoweredByLogo;
