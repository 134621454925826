const Constants = {
  Locations: {
    FETCH: 'locations:fetch:start',
    FETCH_FAILURE: 'locations:fetch:failure',
    FETCH_SUCCESS: 'locations:fetch:success',
    FETCH_IMAGE: 'locations:fetchImage:start',
    FETCH_IMAGE_SUCCESS: 'locations:fetchImage:success',
    RESET: 'locations:reset:start'
  },

  Selections: {
    CHANGE: 'selections:change:start',
    RESET: 'selections:reset:start',
    SUBMIT: 'selections:submit:start',
    SUBMIT_FAILURE: 'selections:submit:failure',
    SUBMIT_SUCCESS: 'selections:submit:success',
    FETCH_LOCATION_IMAGE_SUCCESS: 'selections:fetchLocationImage:success'
  },

  CurrentUser: {
    FETCH: 'currentUser:fetch:start',
    FETCH_SUCCESS: 'curerntUser:fetch:success',
    FETCH_FAILURE: 'currentUser:fetch:failure',
    SIGNUP: 'currentUser:signup:start',
    SIGNUP_SUCCESS: 'currentUser:signup:success',
    SIGNUP_FAILURE: 'currentUser:signup:failure',
    SIGNIN: 'currentUser:signin:start',
    SIGNIN_SUCCESS: 'currentUser:signin:success',
    SIGNIN_FAILURE: 'currentUser:signin:failure',
    SET_CONTACT_DETAILS: 'currentUser:setContactDetails'
  }
};

export default Constants;
