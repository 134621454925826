import {
  connect
} from 'react-redux';
import Constants from 'config/constants';
import TravelDates from './travel_dates';

const mapStateToProps = ({ selections: { dates } }) => ({
  data: dates
});


const mapDispatchToProps = (dispatch) => ({
  select: (data) => {
    dispatch({
      type: Constants.Selections.CHANGE,
      subject: 'dates',
      data
    });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(TravelDates);
