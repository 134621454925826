import React from 'react'
import styles from './GlobeLoaderHeader.module.scss'
import Container from '../container/index'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import Logo from './logo'

const GlobeLoaderHeader = () => {
  return (
    <Container>
      <div className={styles.globeLoaderHeader}>
        <Logo className={styles.logo} />
        <div className={styles.skeletonMain}>
          <SkeletonTheme color='#DADADA' highlightColor='#D4D4D4'>
            <Skeleton className={styles.bigSkeleton} height='36px' />
            <Skeleton className={styles.shortSkeleton} height='36px' />
            <div className={styles.blankspace} />
            <Skeleton className={styles.veryShortSkeleton} height='20px' />
            <Skeleton className={styles.veryShortSkeleton} height='20px' />
            <Skeleton className={styles.veryShortSkeleton} height='20px' />
          </SkeletonTheme>
        </div>
      </div>
    </Container>
  )
}

export default GlobeLoaderHeader
