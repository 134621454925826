import { PureComponent } from 'react';
import T from 'prop-types';


class ServerAuthentication extends PureComponent {
  constructor(props) {
    super();

    this.state = {
      done: props.authenticated || props.fetched
    };
  }

  componentDidMount() {
    if (this.state.done !== true) {
      this.props.fetchCurrentUser();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.fetched === true && prevProps.fetched === false) {
      this.finish();
    }
  }

  finish() {
    this.setState({ done: true });
  }

  render() {
    return typeof this.props.children === 'function'
      ? this.props.children(this.state.done, this.props.authenticated)
      : null;
  }
}
ServerAuthentication.propTypes = {
  fetchCurrentUser: T.func.isRequired,
  authenticated: T.bool.isRequired,
  children: T.func,
  fetched: T.bool.isRequired
};
ServerAuthentication.defaultProps = {
  children: null
};

export default ServerAuthentication;
