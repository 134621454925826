import {
  connect
} from 'react-redux';
import Constants from 'config/constants';
import GetStarted from './get_started';


const mapDispatchToProps = (dispatch) => ({
  reset: () => {
    dispatch({
      type: Constants.Selections.RESET
    });
  }
});

export default connect(null, mapDispatchToProps)(GetStarted);
