import {
  takeLatest,
  call,
  put,
  takeEvery
} from 'redux-saga/effects';

import Api from 'config/api';
import Constants from 'config/constants';

function* fetchLocations({ query }) {
  const params = {
    search: query
  };
  const url = '/v1/trip_finder/destinations';

  try {
    const {
      json
    } = yield call(Api.get, url, params);
    const { data, meta } = json;

    yield put({
      type: Constants.Locations.FETCH_SUCCESS,
      data: data.map(({ slug, name, coverImage }) => ({
        id: slug,
        slug,
        countryName: name,
        tripCount: meta.counters[slug],
        imageUrl: (coverImage && coverImage.url) || ''
      })),
      query
    });
  } catch (e) {
    yield put({
      type: Constants.Locations.FETCH_FAILURE
    });
  }
}

function* fetchLocationImage({ opts }) {
  // const {
  //   json
  // } = yield call(Api.get, `/restapi/countries/image/id/${opts.id}`);

  const data = {
    id: opts.id,
    imageUrl: ''
  };

  yield put({
    type: Constants.Locations.FETCH_IMAGE_SUCCESS,
    data
  });

  yield put({
    type: Constants.Selections.FETCH_LOCATION_IMAGE_SUCCESS,
    data
  });
}

export default [
  takeLatest(Constants.Locations.FETCH, fetchLocations),
  takeEvery(Constants.Locations.FETCH_IMAGE, fetchLocationImage)
];
