import React, { useCallback, useRef, useEffect, useState } from 'react';
import T from 'prop-types';
import { specificWishesShape } from 'reducers/selections';

import styles from './styles';

const placeholder =
  "Start typing and give us your tell all for this trip!"

const SpecificWishesForm = ({ data, select }) => {
  const textareaRef = useRef(null);
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  const onChange = useCallback((e) => {
    select({ text: e.target.value });
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleInput = (event) => {
    if (screenSize.width <= 768) {
      const textarea = textareaRef.current;
      textarea.style.height = 'auto'; // Reset the height
      textarea.style.height = `${textarea.scrollHeight}px`; // Set the height to the scroll height
    }
    onChange(event);
  };

  return (
    <div className={styles.root}>
      <textarea
        ref={textareaRef}
        placeholder={placeholder}
        className={styles.textarea}
        onChange={handleInput}
        value={data.text}
        expandOnFocus
      />
    </div>
  );
};
SpecificWishesForm.propTypes = {
  data: specificWishesShape.isRequired,
  select: T.func.isRequired
};
export default SpecificWishesForm;
